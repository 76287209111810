import { fetch } from '../utils/request'
const defaultState = {
	dict: {},
	city: [],
	carTypeList: [],
	carTypeMap: {},
	vanType: [],
	specifications: [],
}
const config = (state= defaultState, action) => {
	let { type, key, data } = action
	switch (type) {
		case 'UPDATE_DICT':
			let newDict = {
				...state.dict,
				[key]: data
			}
			return Object.assign({}, state, {
				dict: newDict
			})
		case 'UPDATE_CITY':
			return Object.assign({}, state, {
				city: data
			})
		case 'UPDATE_CAR_TYPE':
			let map = {}
			data.forEach(item => {
				map[item.id] = item
			})
			return Object.assign({}, state, {
				carTypeList: data,
				carTypeMap: map
			})
		default:
			return state
	}
}

/**
 * 获取字典
 * 1,"CAR_SPECIFI", "车辆特殊规格"
 * 2,"DRIVER_FEEDBACK", "司机意见反馈"
 * 3,"DRIVER_AUDIT", "司机认证提示"
 * 4,"CONTACT", "反馈联系文案"
 * 5,"VAN_TYPE", "车厢类型"
 * 6,"", "审核不通过原因"
 * */
export function getDict(type){
	return async (dispatch, getState) => {
		let { config } = getState()
		if(config.dict[type]){
			return
		}
		let { err, data } = await fetch({
			url: '/dict/listDict',
			params: {
				type
			}
		})
		if(!err){
			dispatch({
				type: 'UPDATE_DICT',
				key: type,
				data: data.data
			})
		}
	}
}
export function getCity(){
	return async (dispatch, getState) => {
		let { config } = getState()
		if(config.city.length){
			return
		}
		let {err, data} = await fetch({
			url: '/city/listCitys'
		})
		if(!err){
			dispatch({
				type: 'UPDATE_CITY',
				data: data.data
			})
		}
	}
}

export function getCarType(){
	return async (dispatch, getState) => {
		let { config } = getState()
		if(config.carTypeList.length){
			return
		}
		let {err, data} = await fetch({
			url: '/configCarType/listCarTypes'
		})
		if(!err){
			dispatch({
				type: 'UPDATE_CAR_TYPE',
				data: data.data
			})
		}
	}
}
export default config