function arrToObjWalk(obj, data, key, child, parent = null){
  data.forEach(item => {
    obj[item[key]] = {
      ...item,
      parent,
    }
    if(item[child] && item[child].length){
      arrToObjWalk(obj, item[child], key, child, item)
    }
  })
}
/**
 * 将对象数组转为扁平对象
 * @params {Array||Object} data
 * @params {key} 使用的 key 名
 * @params {child} 子元素键名
 * */
export function arrToObj(data = [], key, child){
  let obj = {}
  const type = Object.prototype.toString.call(data)
  if(type === '[object Object]'){
    data = [data]
  }
  arrToObjWalk(obj, data, key, child)
  return obj
}
export function add0(m){ return m < 10 ? '0' + m : m }
export function formatDate(time) {
  if(!time) return
  time = new Date(time);
  let y = time.getFullYear();
  let m = time.getMonth()+1;
  let d = time.getDate();
  let h = time.getHours();
  let mm = time.getMinutes();
  // var s = time.getSeconds();
  // return y + '-' + add0(m) + '-'+add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
  return y + '-' + add0(m) + '-'+add0(d) + ' ' + add0(h) + ':' + add0(mm)
}

export function wait(time){
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}
export function getDateInstance(date){
  if(!date){
    return new Date()
  }
  if(typeof date === 'string'){
    date = date.replace(/-/g, '/')
  }
  return new Date(date)
}
export function dateSplit(date){
  if(typeof date === 'string' && date.indexOf('T') < 0){
    date = date.replace(/-/g, '/')
  }
  date = new Date(date)
  return {
    year: date.getFullYear(),
    month: date.getMonth()+1,
    day: date.getDate(),
    week: date.getDay(),
    hour: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    time: date.getTime(),
  }
}
export function dateYYD(str){
  if(!str) return str
  const {year, month, day} = dateSplit(str)
  return `${year}-${add0(month)}-${add0(day)}`
}
export function hidePhone(str){
  if(!str) return str
  let result = ''
  for(let i = 0; i<str.length; i++){
    result = result + ((i >2 && i<7) ? '*' : str[i])
  }
  return result
}
export function hideIDNum(str){
  if(!str) return str
  let result = ''
  for(let i = 0; i<str.length; i++){
    result = result + ((i>5) ? '*' : str[i])
  }
  return result
}

export function isU(data){
  return data === undefined || data === null

}

export function toFormData(data){
  let formData = new FormData()
  for(let key of Object.keys(data)){
    const value = data[key]
    formData.append(key, value)
  }
  return formData
}