import React, {useEffect, useState} from "react";
import { getClientInfo } from '@/src/store/client'
import { Button, Space} from 'antd'
import queryString from 'query-string'
import ClientInfo from './ClientInfo'
import OrderList from './OrderInfo/index'

let tagList = [
	{label: '用户信息', key: 0},
	{label: '订单信息', key: 1},
]
export default function Page(props){
	let [ client, setDriverInfo ] = useState({})
	const { clientId, type=0 } = queryString.parse(props.location.search)
	async function fetchClient(clientId){
		let { err, data} = await getClientInfo(clientId)
		if(!err){
			setDriverInfo(data.data)
		}
	}
	useEffect(() => {
		fetchClient(clientId)
	}, [])
	// 标签
	let [tagIndex, setTag] = useState(+type)
	function toggleTag(key){
		props.history.push(`/home/client/detail?clientId=${clientId}&type=${key}`)
		setTag(key)
	}
	return (
		<div>
			<div className="panel">
				<div style={{marginBottom: '15px', fontSize: '14px'}}>
					<span className="label-color">姓名：</span> <span style={{marginRight: '30px'}}>{client.nickname}</span>
					<span className="label-color">下单总数：</span><span style={{marginRight: '30px'}}>{client.orderCount||0}</span>
					<span className="label-color">已完成订单数：</span><span style={{marginRight: '30px'}}>{client.finishedOrderCount}</span>
				</div>
				<div className="tab-box">
					<Space size={5}>
						{
							tagList.map(item => (
								<Button
									type={item.key === tagIndex ? 'primary': ''}
									size="large"
									onClick={() => toggleTag(item.key)}
									key={item.key}
								>
									{item.label}
								</Button>
								)
							)
						}
					</Space>
				</div>
			</div>
			{ tagIndex === 0 && <ClientInfo dataInfo={client}></ClientInfo>}
			{ tagIndex === 1 && <OrderList dataInfo={{userId: clientId}}></OrderList>}
		</div>
	)
}