import {Modal, Button} from "antd";
import React from "react";

export default function (props){
	const { children, onCancel, isLook, footer: slotFooter, ...modalAttr} = props
	let footer = []
	if(slotFooter){
		footer = slotFooter
	} else {
		footer = isLook ? [
			<Button key="back" onClick={onCancel} loading={props.loading}>
				关闭
			</Button>,
		] : [
			[
				<Button key="back" onClick={onCancel} loading={props.loading}>
					取消
				</Button>,
				<Button key="submit" type="primary" onClick={props.onConfirm} loading={props.loading}>
					确定
				</Button>
			]
		]
	}
	return <Modal
		bodyStyle={{padding: 0}}
		width={1000}
		destroyOnClose
		footer={footer}
		onCancel={onCancel}
		{...modalAttr}
	>
		<div className="ant-modal-body">
			{props.children}
		</div>
	</Modal>

}
