import {useContext} from 'react'
import {Menu, Dropdown, message} from 'antd';
import { DownOutlined, RightOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './layout.less'
import { menuStackContext } from '../context/index'
import {fetch, userToken} from "@/src/utils/request";

function Header(){
  let history = useHistory();
  async function loginOut(){
    const {err} = await fetch({
      url: '/login/backStageLoginOut',
    })
    if(!err){
      message.success('已退出登录')
      userToken.setValue('')
      history.push('/login')
    }
  }
  const menu = (
    <Menu>
      <Menu.Item onClick={loginOut}>
      <span>
        退出登录
      </span>
      </Menu.Item>
    </Menu>
  );
  const [menuStack] = useContext(menuStackContext)
  return (
    <div className="head flex">
      <div className="flex-1 flex align-center" style={{color: '#606266', minWidth: '167px'}}>
        <MenuUnfoldOutlined style={{marginRight: '7px', color: '#666'}}/>
        {
          menuStack&&menuStack.map((item, index) => {
            return (<div className="shrink" key={index}>{index > 0 && <RightOutlined />}<span>{item.label}</span></div>)
          })
        }
      </div>
      <div className="flex align-center">
        <img src="https://i.gtimg.cn/club/item/face/img/8/15918_100.gif" alt="" className="user-avatar"/>
        <Dropdown overlay={menu} placement="bottomRight" arrow>
          <div className="flex align-center">
            <div className="name">admin</div>
            <DownOutlined style={{color: '#606266', top: '2px', position: 'relative'}} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}
export default Header;
