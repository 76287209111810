import React, {useEffect, useState, useRef} from "react";
import { useHistory } from 'react-router-dom';
import {Button, DatePicker, Form, Input, Select, Space} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {getCarType} from "@/src/store/config";
import TableView from "@/src/components/view/table/index"
import moment from "moment";
import { filterOrderStatusList as statusList, paymentType} from '@/src/utils/helper'
import { tableColumns } from './tableConfig'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const mobileTypeList = [
	{ key: 2, label: '司机手机号'},
	{ key: 1, label: '用户手机号'},
]
const orderFilterTypeList = [
	{ key: 1, label: '下单时间'},
	{ key: 2, label: '接单时间'},
	{ key: 3, label: '拒单时间'},
]
// 不可选择的时候
function disabledDate(current) {
	return current && current > moment().endOf('day');
}
export default function List(){
	let history = useHistory();
	const { carTypeMap } = useSelector(state => state.config);
	const dispatch = useDispatch()

	/*筛选*/
	const [filterForm] = Form.useForm();
	function resetFilter(){
		filterForm.resetFields();
		getTableData(filterForm.getFieldValue())
	}
	/*表格*/
	const viewTable = useRef(null);
	function tableAction(row){
		history.push(`/home/driver/orderDetail?sn=${row.sn}`)
	}
	let [columns, setColumns] = useState([])
	useEffect(async () => {
		await dispatch(getCarType())
		setColumns(tableColumns(tableAction))
		getTableData(filterForm.getFieldValue())
	}, [])
	async function getTableData(params){
		let ps = {
			...params,
			type: 2
		}
		if(ps.rangeDate && ps.rangeDate.length){
			ps.beginDateStr = ps.rangeDate[0].format(dateFormat)
			ps.endDateStr = ps.rangeDate[1].format(dateFormat)
		} else {
			delete ps.dateType
		}
		delete ps.rangeDate
		if(!ps.mobile){
			delete ps.mobileType
		}
		viewTable.current.reLoad(ps)
	}
	function filterData(data = []){
		return data.map(item => ({ ...item, carTypeName: carTypeMap[item.carType] ?  carTypeMap[item.carType].carName: item.carType}))
	}
	return (
		<div>
			<div className="filer-layout">
				<Form
					form={filterForm}
					layout="inline"
					onFinish={getTableData}
					initialValues={{dateType: 1, mobileType: 2}}
				>
					<div className="flex">
						<div className="flex-1 relative">
							<Space size={[8, 16]} wrap>
								<Form.Item label="">
									<Input.Group>
										<Form.Item
											name="mobileType"
											noStyle
										>
											<Select style={{width: '120px'}}>
												{mobileTypeList.map(item => (
													<Select.Option key={item.key} value={item.key}>{item.label}</Select.Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item
											name="mobile"
											noStyle
										>
											<Input autoComplete="off" placeholder="请输入搜索号码" allowClear maxLength={11} style={{ width: 'auto' }}/>
										</Form.Item>
									</Input.Group>

								</Form.Item>
								<Form.Item label="">
									<Input.Group>
										<Form.Item
											name="dateType"
											noStyle
										>
											<Select style={{width: '110px'}}>
												{orderFilterTypeList.map(item => (
													<Select.Option key={item.key} value={item.key}>{item.label}</Select.Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item
											name="rangeDate"
											noStyle
										>
											<RangePicker format={dateFormat} disabledDate={disabledDate}/>
										</Form.Item>
									</Input.Group>

								</Form.Item>
								<Form.Item
									name="status"
									label="订单状态"
								>
									<Select style={{width: 140}} allowClear placeholder="选择订单状态">
										{statusList.map(item => (
											<Select.Option key={item.key} value={item.key}>{item.label}</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item
									name="payment"
									label="支付方式"
								>
									<Select style={{width: 140}} allowClear placeholder="选择支付方式">
										{paymentType.map(item => (
											<Select.Option key={item.key} value={item.key}>{item.label}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Space>
						</div>
						<div className="shrink">
							<Form.Item className="shrink">
								<div className="filter-form-control">
									<Button type="primary" htmlType="submit">
										查询
									</Button>
									<Button
										style={{margin: '0 15px'}}
										onClick={resetFilter}
									>
										重置
									</Button>
								</div>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="table-layout">
				<TableView
					ref={viewTable}
					fetchOption={{url: '/order/pageOrders', method: 'post'}}
					columns={columns}
					filterData={filterData}
					rowKey="sn"
				/>
			</div>
		</div>
	)
}