import React, {useEffect, useState, useRef} from "react";
import { getDriverInfo, getCarInfo } from '@/src/store/driver'
import { Button, Space, Row, Col, Image } from 'antd'
import queryString from 'query-string'
import DriverInfo from './DriverInfo'
import CarInfo from './CarInfo'
import OrderList from './OrderInfo/index'

let tagList = [
	{label: '司机信息', key: 0},
	{label: '车辆信息', key: 1},
	{label: '订单信息', key: 2},
]
export default function Page(props){
	let [ driverInfo, setDriverInfo ] = useState({})
	const { driverId, type=0 } = queryString.parse(props.location.search)
	async function fetchDrive(driverId){
		let { err, data} = await getDriverInfo(driverId)
		if(!err){
			setDriverInfo(data.data)
		}
	}
	let [ carInfo, setCarInfo ] = useState({})
	async function fetchCar(driverId){
		let { err, data} = await getCarInfo(driverId)
		if(!err){
			setCarInfo(data.data)
		}
	}
	useEffect(() => {
		fetchDrive(driverId)
		fetchCar(driverId)
	}, [])
	// 标签
	let [tagIndex, setTag] = useState(+type)
	function toggleTag(key){
		props.history.push(`/home/driver/detail?driverId=${driverId}&type=${key}`)
		setTag(key)
	}

	return (
		<div>
			<div className="panel">
				<div style={{marginBottom: '15px', fontSize: '14px'}}>
					<span className="label-color">姓名：</span> <span style={{marginRight: '30px'}}>{driverInfo.nickname}</span>
					<span className="label-color">ID：</span><span style={{marginRight: '30px'}}>{driverInfo.userId}</span>
					<span className="label-color">评分：</span><span style={{marginRight: '30px'}}>{(driverInfo.score||0)/10}</span>
				</div>
				<div className="tab-box">
					<Space size={5}>
						{
							tagList.map(item => (
								<Button
									type={item.key === tagIndex ? 'primary': ''}
									size="large"
									onClick={() => toggleTag(item.key)}
									key={item.key}
								>
									{item.label}
								</Button>
								)
							)
						}
					</Space>
				</div>
			</div>
			{ tagIndex === 0 && <DriverInfo dataInfo={driverInfo}></DriverInfo>}
			{ tagIndex === 1 && <CarInfo dataInfo={carInfo}></CarInfo>}
			{ tagIndex === 2 && <OrderList dataInfo={{userId: driverId}}></OrderList>}
{/*			{
				tagIndex === 0 ? DriverInfo({...props, dataInfo: driverInfo}): tagIndex === 1 ? CarInfo({...props, dataInfo: carInfo}) : DriverInfo({...props, dataInfo: driverInfo})
			}*/}
		</div>
	)
}