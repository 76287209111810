import React, {useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {message, Space, Table,} from "antd";
import {fetch} from "@/src/utils/request";
import { invalidTag } from '@/src/utils/helper'

let actionsBtnStyle = {
	look: {
		className: 'color-4a67de'
	}
}
function geColumns(columns, showIndex){
	let widthCount = 0
	let data = columns.map(item => {
		let current = item
		if(item.key === 'action'){
			current = {
				title: '操作',
				key: 'action',
				fixed: 'right',
				width: item.btn.length*80,
				render: (text, record) => (
					<Space size="middle" key={item.type}>
						{item.btn.map(it => {
							let className = `${actionsBtnStyle[it.type].className} pointer`
							return (<span key={it.type} onClick={() => item.action(record, it.type)} className={className}>{it.label}</span>)
						})}

					</Space>
				),
			}
		} else if(!item.render){
			current = {
				...item,
				render: (text) => (
					<div className={item.nowrap ? 'nowrap': ''}>{text === null ? invalidTag: text}</div>
				)
			}
		}
		widthCount += (current.width || 100)
		return current
	})
	if(showIndex){
		data.unshift({
			title: '序号',
			width: 70,
			render: (text, record, index) => {
				return (<span>{index+1}</span>)
			},
		})
		widthCount += 70
	}
	return [data, widthCount]
}
let paramsData = {}
function ViewTable(props, ref){
	const {
		fetchOption = {},
		columns = [],
		filterData,
		filterResponse,
		showIndex = false
	} = props
	let [columnsList, widthCount] = geColumns(columns, showIndex)
	const { url, method = 'get' } = fetchOption
	let [ isLoading, setLoading ] = useState(false)
	// 保存列表数据
	let [dataList, setDataList] = useState([])
	// 分页
	let [pageInfo, setPageInfo] = useState({
		current: 1,
		pageSize: 10,
	})
	let [total, setTotal] = useState(0)

	async function getData(page=pageInfo){
		if(!url){
			message.error(`请配置 url: viewTable`)
		}
		const params = {
			size: page.pageSize,
			current: page.current,
			...paramsData
		}
		setLoading(true)
		const fetchConfig = {
			url,
			method,
		}
		if(method === 'post'){
			fetchConfig.data = { ...params }
		} else {
			fetchConfig.params = { ...params }
		}
		const {err, data} = await fetch(fetchConfig)
		setLoading(false)
		if(!err){
			const result = filterResponse ? filterResponse(data.data): data.data
			const { records, total } = result
			let resultData = filterData ? filterData(records) : records
			setDataList( resultData)
			setTotal(total)
		}
	}
	function onChange(e, size=pageInfo.pageSize){
		let params = {
			...pageInfo,
			pageSize: size,
			current: e
		}
		setPageInfo(params)
		getData(params)
	}
	// ref
	useImperativeHandle(ref, () => ({
		refresh: getData,
		reLoad: (params = {}) => {
			paramsData = params
			onChange(1)
		},
	}));
	return (
		<div className="view-table">
			<Table
				{...props}
				loading={isLoading}
				columns={columnsList}
				dataSource={dataList}
				rowKey={props.rowKey||'id'}
				scroll={{ x: widthCount }}
				pagination={
					{
						current: pageInfo.current,
						pageSize: pageInfo.pageSize,
						total: total,
						showTotal: (total, range) => `共 ${total} 条`,
						showSizeChanger: true,
						onChange: onChange,
					}
				}
			/>

		</div>
	)
}

export default forwardRef(ViewTable)
