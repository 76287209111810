import React, {useEffect, useState, useRef} from "react";
import { useHistory } from 'react-router-dom';
import {Button, DatePicker, Form, Input, Select, Space} from "antd";
import TableView from "@/src/components/view/table"
import { filterOrderStatusList as statusList} from '@/src/utils/helper'
import moment from "moment";
import { tableColumns } from './tableConfig'
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const dateTypeList = [
	{ key: 1, label: '下单时间'},
	{ key: 2, label: '接单时间'},
	{ key: 3, label: '拒单时间'},
]
// 不可选择的时候
function disabledDate(current) {
	return current && current > moment().endOf('day');
}
export default function Page({dataInfo}){
	let history = useHistory();
	/*筛选*/
	const [filterForm] = Form.useForm();
	function resetFilter(){
		filterForm.resetFields();
		getTableData(filterForm.getFieldValue())
	}
	/*表格*/
	const viewTable = useRef(null);
	function tableAction(row, type){
		history.push(`/home/driver/detail?driverId=${row.userId}`)
	}
	let [columns, setColumns] = useState([])
	useEffect(() => {
		setColumns(tableColumns(tableAction))
		getTableData(filterForm.getFieldValue())
	}, [])

	async function getTableData(params){
		let ps = {
			...params,
			userId: dataInfo.userId,
			type: 2
		}
		if(ps.registerDate && ps.registerDate.length){
			ps.beginDateStr = ps.registerDate[0].format(dateFormat)
			ps.endDateStr = ps.registerDate[1].format(dateFormat)
		} else{
			delete ps.dateType
		}
		delete ps.registerDate
		viewTable.current.reLoad(ps)
	}
	return (
		<div>
			<div className="filer-layout">
				<Form
					form={filterForm}
					layout="inline"
					onFinish={getTableData}
					initialValues={{dateType: 1}}
				>
					<div className="flex">
						<div className="flex-1 relative">
							<Space size={[8, 16]} wrap>
								<Form.Item label="" name="registerDate">
									<Input.Group>
										<Form.Item
											name="dateType"
											noStyle
										>
											<Select style={{width: '110px'}}>
												{dateTypeList.map(item => (
													<Select.Option key={item.key} value={item.key}>{item.label}</Select.Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item
											name="registerDate"
											noStyle
										>
											<RangePicker format={dateFormat} disabledDate={disabledDate}/>
										</Form.Item>
									</Input.Group>

								</Form.Item>
								<Form.Item label="订单状态" name="status">
									<Select style={{width: 140}} allowClear placeholder="选择订单状态">
										{statusList.map(item => (
											<Select.Option key={item.key} value={item.key}>{item.label}</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item label="手机号" name="clientMobile">
									<Input autoComplete="off" placeholder="请输入手机号" allowClear maxLength={11}  />
								</Form.Item>
							</Space>
						</div>
						<div className="shrink">
							<Form.Item className="shrink">
								<div className="filter-form-control">
									<Button type="primary" htmlType="submit">
										查询
									</Button>
									<Button
										style={{margin: '0 15px'}}
										onClick={resetFilter}
									>
										重置
									</Button>
								</div>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="table-layout">
				<TableView
					ref={viewTable}
					fetchOption={{url: '/order/pagePersonalOrders', method: 'post'}}
					columns={columns}
					rowKey="sn"
				/>
			</div>
		</div>
	)
}