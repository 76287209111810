import { useState } from "react";
import { Button } from "antd"
export default function (hideStr, realStr){
	let [show, toggle] = useState(false)
	function toggleShow(){
		toggle(!show)
	}
	if(!hideStr&&!realStr) return ''
	return (
		<div className="flex align-center">
			{ show ? <span>{realStr}</span>: 	<span>{hideStr}</span>}
			<Button type="primary" style={{marginLeft: '10px'}}>
				<span onClick={toggleShow}>{show ? '隐藏': '显示'}</span>
			</Button>
		</div>
	)
}