import React, {useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {Button, Col, DatePicker, Form, Input, Modal, Row, Space} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import {getDict} from "@/src/store/config";
import TableView from "@/src/components/view/table/index"
import ModalView from "@/src/components/view/modal/index"
import TextList from '@/src/components/view/textList'
import { tableColumns } from './tableConfig'
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
// 不可选择的时候
function disabledDate(current) {
	return current && current > moment().endOf('day');
}
export default function List(){
	let history = useHistory();
	const dispatch = useDispatch()

	/*筛选*/
	const [filterForm] = Form.useForm();
	function resetFilter(){
		filterForm.resetFields();
		getTableData(filterForm.getFieldValue())
	}
	/*表格*/
	const viewTable = useRef(null);
	function tableAction(row){
		const result = []
		columns.forEach(item => {
			const { title, dataIndex, key } = item
			if(key === 'action'){
				return
			}
			const res = {
				...item,
				label: title,
				value: dataIndex
			}
			if(dataIndex === 'payContent'){
				delete res.render
			}
			result.push(res)
		})
		setDataList(result)
		setRowInfo(row)
		setModalOpen(true)
	}
	let columns = tableColumns(tableAction)
	useEffect(async () => {
		await dispatch(getDict(2))
		getTableData(filterForm.getFieldValue())
	}, [])
	async function getTableData(params){
		let ps = {
			...params,
		}
		if(ps.tradeTime && ps.tradeTime.length){
			ps.tradeTimeStart	 = ps.tradeTime[0].format(dateFormat)
			ps.tradeTimeEnd = ps.tradeTime[1].format(dateFormat)
		}
		delete ps.tradeTime
		viewTable.current.reLoad(ps)
	}
	function filterData(data){
		return {
			current: 1,
			records: data,
			size: 10,
			total: data.length,
		}
	}
	/*Modal*/
	let [isModalOpen, setModalOpen] = useState(false)
	let [rowInfo, setRowInfo] = useState({})
	let [dataList, setDataList] = useState([])
	function handleCancel(){
		setModalOpen(false)
	}
	return (
		<div>
			<div className="filer-layout">
				<Form
					form={filterForm}
					layout="inline"
					onFinish={getTableData}
					initialValues={{dateType: 1, mobileType: 2}}
				>
					<div className="flex">
						<div className="flex-1 relative">
							<Space size={[8, 16]} wrap>
								<Form.Item name="tradeTime" label="支付时间">
									<RangePicker format={dateFormat} disabledDate={disabledDate}/>
								</Form.Item>
								<Form.Item name="userId" label="支付账号">
									<Input style={{width: 150}} placeholder="请输入支付账号" allowClear />
								</Form.Item>
							</Space>
						</div>
						<div className="shrink">
							<Form.Item className="shrink">
								<div className="filter-form-control">
									<Button type="primary" htmlType="submit">
										查询
									</Button>
									<Button
										style={{margin: '0 15px'}}
										onClick={resetFilter}
									>
										重置
									</Button>
								</div>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="table-layout">
				<TableView
					showIndex
					filterResponse={filterData}
					ref={viewTable}
					fetchOption={{url: '/pos-admin/pay/queryWxRecords', method: 'get'}}
					columns={columns}
				/>
			</div>
			<ModalView title="支付明细" visible={isModalOpen} isLook onCancel={handleCancel}>
				<Row>
					<Col span={20}>
						{TextList({ dataList, dataSource: rowInfo, labelStyle: {width: '130px'} })}
					</Col>
				</Row>
			</ModalView>
		</div>
	)
}
