import {formatDate, hidePhone} from "@/src/utils";
import React from "react";
import LinkText from '@/src/components/LinkText'

export const tableColumns  = function (doAction){
	return [
		{
			title: '司机ID',
			dataIndex: 'userId',
			nowrap: true,
			render: (text) => (<LinkText link={'/home/driver/detail?driverId='+text}>{text}</LinkText>)
		},
		{
			title: '司机姓名',
			dataIndex: 'username',
			nowrap: true
		},
		{
			title: '注册手机',
			dataIndex: 'mobile',
			nowrap: true,
			render: hidePhone
		},
		{
			title: '意见分类',
			dataIndex: 'typeName',
			sorter: (a, b) => {
				return a.typeName.localeCompare(b.typeName)
			},
			nowrap: true
		},
		{
			title: '提交时间',
			dataIndex: 'createAt',
			nowrap: true,
			sorter: (a, b) => {
				return a.createAt.localeCompare(b.createAt)
			},
			render: (createAt) => (<div className="nowrap">{formatDate(createAt)}</div>)
		},
		{
			title: '反馈内容',
			dataIndex: 'content',
			render: (text) => (<div style={{wordBreak: "break-all"}}>{text}</div>),
			width: 400
		},
	];
}