import React from "react";
import { Row, Col, Image } from 'antd'
import TextList from '@/src/components/view/textList'
import Desensitization from '@/src/components/view/desensitization'
import { dateYYD, hidePhone, hideIDNum } from '@/src/utils/index'
import { getAuditStatus } from '@/src/utils/helper'

export default function Page({dataInfo}){
	let dataList = [
		{ label: '认证状态', value: 'auditStatus', render: (val) => (<span style={{color: getAuditStatus(val).color}}>{getAuditStatus(val).name}</span>)},
		{ label: '身份证号', value: 'idCard', render: (val) => Desensitization(hideIDNum(val), val), alignCenter: true},
		{ label: '身份证号有效期至',  value: 'idCardValidityDate', render: dateYYD},
		{ label: '手机号', value: 'mobile', render: (val) => Desensitization(hidePhone(val), val), alignCenter: true},
		{ label: '城市', value: 'city'},
		{ label: '端口来源', value: 'sourceName'},
		{ label: '注册来源', value: 'registerSourceName'},
		{ label: '性别', value: 'gender'},
		{ label: '初始领证日期', value: 'licenseAcquireDate', render: dateYYD},
		{ label: '驾驶证有效期至', value: 'licenseValidityDate', render: dateYYD},
		{ label: '准驾车型', value: 'drivingType'},
		{ label: '注册时间', value: 'createAt', render: dateYYD},
		{ label: '申请认证时间', value: 'applyTime', render: dateYYD},
		{ label: '认证完成时间', value: 'auditTime', render: dateYYD},
	]
	const { rates = {} } = dataInfo
	const userOverstepList = rates.userOverstepList ? rates.userOverstepList.slice(0, rates.userOverstepList.length-1) : []
	const lastStep = rates.userOverstepList ? rates.userOverstepList[rates.userOverstepList.length-1]: {}
	return (
		<div className="panel">
			<Row>
				<Col span={12}>
					<div className="text-title">基本资料：</div>
					{TextList({ dataList, dataSource: dataInfo })}
				</Col>
				<Col span={12}>
					<div className="text-title">附加资料：</div>
					<Row>
						<Col span={12}>
							<div className="col-value">身份证正面</div>
							<Image
								width={200}
								src={dataInfo.idCardFront}
							/>
						</Col>
						<Col span={12}>
							<div className="col-value">身份证反面</div>
							<Image
								width={200}
								src={dataInfo.idCardVerso}
							/>
						</Col>
					</Row>
					<Row style={{marginTop: '50px'}}>
						<Col span={12}>
							<div className="col-value">驾驶证正面</div>
							<Image
								width={200}
								src={dataInfo.driverLicense}
							/>
						</Col>
					</Row>
				</Col>
				{
					!rates.userFees ? null:(
						<Col span={12}>
							<div className="text-title">收费标准：</div>
							<div className="col-value">
								<table className="table-my">
									<tbody>
									<tr>
										<td>起步价（{rates.userFees.startMeter/1000}）公里</td>
										<td>{rates.userFees.startPrice/100}元/公里</td>
									</tr>
									{
										userOverstepList.map((item, index) => (
											<tr key={index}>
												<td>里程区间价 （{item.openMeter/1000}-{item.openMeterLimit/1000}公里）</td>
												<td>{item.price/100}元/公里</td>
											</tr>
										))
									}

									<tr>
										<td>超里程价（{lastStep.openMeter/1000}公里以上）</td>
										<td>{lastStep.price/100}元/公里</td>
									</tr>
									</tbody>
								</table>
							</div>
						</Col>
					)
				}

			</Row>
		</div>
	)
}