import React, {useEffect, useState} from 'react'
import {fetch} from "@/src/utils/request";
import { invalidTag } from '@/src/utils/helper'
import { dateYYD } from '@/src/utils/index'
import {Button, Col, Row} from "antd";
import { Column } from '@ant-design/charts';
import './home.less'

let tagList = [
  {label: '注册用户数（近7天）', key: 0},
  {label: '访问次数（近7天）', key: 1},
]
let registerData = []
let loginData = []
function Home(){
  let [data, setData] = useState({})
  async function getData(){
    let { err, data } = await fetch({
      url: '/statistics/getStatisticsInfo',
    }, { noToast: true })
    if(!err){
      setData(data.data)
      initChartData(data.data)
    }
  }
  useEffect(() => {
    getData()
  }, [])


  let [chartData, setChartData] = useState([])
  let [chartType, setChartType] = useState(0)
  function toggleTag(key){
    setChartType(key)
    setChartData(key === 0 ? registerData: loginData)
  }
  function initChartData({lastSevenDaysInfo=[]}={}) {
    lastSevenDaysInfo.forEach(item => {
      registerData.push({
        name: item.dataFrom === 1 ? '用户端': '司机端',
        dataFrom: item.dataFrom,
        日期: dateYYD(item.statisticsDate),
        value: item.registerCount,
      })
      loginData.push({
        name: item.dataFrom === 1 ? '用户端': '司机端',
        dataFrom: item.dataFrom,
        日期: dateYYD(item.statisticsDate),
        value: item.loginCount,
      })
    })
    // 把司机的数据放在前面，优先显示司机端
    registerData.sort((it1, it2) => (it1.dataFrom < it2.dataFrom ? 1 : -1))
    loginData.sort((it1, it2) => (it1.dataFrom < it2.dataFrom ? 1 : -1))
    toggleTag(0)
  }
  var config = {
    data: chartData,
    isGroup: true,
    xField: '日期',
    yField: 'value',
    seriesField: 'name',
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
    },
    color: ['#ff5103', '#8ea6ef'],

    legend: {
      layout: 'horizontal',
      position: 'bottom',
      itemName: {
        style: {
          fontSize: 16,
        },
      },
      marker: {
        symbol: "square",
        item: [{
          style: {
            stroke: 'black',
          }
        }]
      }
    }

  };
  return (
    <div className="home-page">
      <div className="panel">
        <div className="text-title">用户概览：</div>
        <div className="data-panel">
          <div className="data-box">
            <div className="title">司机端注册用户数</div>
            <div className="num">{data.driverRegisterCount}</div>
            <div className=""><span className="tag">日</span>{!data.driverRegisterDayRate ? invalidTag: data.driverRegisterDayRate+'%' }</div>
            <div className=""><span className="tag">周</span>{!data.driverRegisterWeekRate ? invalidTag: data.driverRegisterWeekRate+'%' }</div>
            <div className=""><span className="tag">月</span>{!data.driverRegisterMonthRate ? invalidTag: data.driverRegisterMonthRate+'%' }</div>
          </div>
          <div className="data-box">
            <div className="title">司机端访问次数（PV）</div>
            <div className="num">{data.driverLoginCount}</div>
            <div className=""><span className="tag">日</span>{data.driverLoginCountDayRate ? data.driverLoginCountDayRate+'%': invalidTag }</div>
            <div className=""><span className="tag">周</span>{data.driverLoginCountWeekRate ? data.driverLoginCountWeekRate+'%': invalidTag }</div>
            <div className=""><span className="tag">月</span>{data.driverLoginCountMonthRate ?  data.driverLoginCountMonthRate+'%': invalidTag }</div>
          </div>
          <div className="data-box">
            <div className="title">用户端注册用户数</div>
            <div className="num">{data.clientRegisterCount}</div>
            <div className=""><span className="tag">日</span>{data.clientRegisterCountDayRate ? data.clientRegisterCountDayRate+'%': invalidTag }</div>
            <div className=""><span className="tag">周</span>{data.clientRegisterCountWeekRate ? data.clientRegisterCountWeekRate+'%': invalidTag }</div>
            <div className=""><span className="tag">月</span>{data.clientRegisterCountMonthRate ?  data.clientRegisterCountMonthRate+'%': invalidTag }</div>
          </div>
          <div className="data-box">
            <div className="title">用户端访问次数（PV）</div>
            <div className="num">{data.clientLoginCount}</div>
            <div className=""><span className="tag">日</span>{data.clientLoginCountDayRate ? data.clientLoginCountDayRate+'%': invalidTag }</div>
            <div className=""><span className="tag">周</span>{data.clientLoginCountWeekRate ? data.clientLoginCountWeekRate+'%': invalidTag }</div>
            <div className=""><span className="tag">月</span>{data.clientLoginCountMonthRate ?  data.clientLoginCountMonthRate+'%': invalidTag }</div>
          </div>
        </div>
      </div>
      <div className="panel">
        <div className="text-title">
          {
            tagList.map(item => (
              <Button
                type={item.key === chartType ? 'primary': ''}
                size="large"
                onClick={() => toggleTag(item.key)}
                key={item.key}
              >
                {item.label}
              </Button>
            )
          )}
        </div>
        <div style={{marginTop: '50px'}}>
          <Column {...config} />
        </div>
      </div>
    </div>
  );
}

export default Home;
