import React from "react";
import { Row, Col } from 'antd'
import TextList from '@/src/components/view/textList'
import Desensitization from '@/src/components/view/desensitization'
import { dateYYD, hidePhone } from '@/src/utils/index'

export default function Page({dataInfo}){
	let dataList = [
		{ label: '来源', value: 'sourceName'},
		{ label: '手机号', value: 'mobile', render: (val) => Desensitization(hidePhone(val), val), alignCenter: true},
		{ label: '城市', value: 'city'},
		{ label: '性别', value: 'gender'},
		{ label: '注册时间', value: 'createAt', render: dateYYD},
	]
	return (
		<div className="panel">
			<Row>
				<Col span={24}>
					<div className="text-title">基本资料：</div>
					{TextList({ dataList, dataSource: dataInfo })}
				</Col>
			</Row>
		</div>
	)
}