import React, {useEffect, useState, useRef} from "react";
import { useHistory } from 'react-router-dom';
import {Button, DatePicker, Form, Input, Select, Space} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {getCity} from "@/src/store/config";
import TableView from "@/src/components/view/table/index"
import { tableColumns } from './tableConfig'
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
// 不可选择的时候
function disabledDate(current) {
	return current && current > moment().endOf('day');
}
export default function List(){
	let history = useHistory();
	const { city } = useSelector(state => state.config);
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getCity())
	}, [])
	/*筛选*/
	const [filterForm] = Form.useForm();
	function resetFilter(){
		filterForm.resetFields();
		getTableData(filterForm.getFieldValue())
	}
	/*表格*/
	const viewTable = useRef(null);
	function tableAction(row, type){
		history.push(`/home/driver/detail?driverId=${row.userId}`)
	}
	let [columns, setColumns] = useState([])
	useEffect(() => {
		setColumns(tableColumns(tableAction))
		getTableData(filterForm.getFieldValue())
	}, [])

	async function getTableData(params){
		let ps = {
			...params
		}
		if(ps.registerDate && ps.registerDate.length){
			ps.beginDateStr = ps.registerDate[0].format(dateFormat)
			ps.endDateStr = ps.registerDate[1].format(dateFormat)
		}
		delete ps.registerDate
		viewTable.current.reLoad(ps)
	}
	return (
		<div>
			<div className="filer-layout">
				<Form
					form={filterForm}
					layout="inline"
					onFinish={getTableData}
				>
					<div className="flex">
						<div className="flex-1 relative">
							<Space size={[8, 16]} wrap>
								<Form.Item label="手机号" name="mobile">
									<Input autoComplete="off" placeholder="请输入手机号" allowClear maxLength={11} />
								</Form.Item>
								<Form.Item label="注册时间" name="registerDate">
									<RangePicker format={dateFormat} disabledDate={disabledDate}/>
								</Form.Item>
								<Form.Item label="司机ID" name="driverId">
									<Input autoComplete="off" placeholder="请输入司机ID" allowClear/>
								</Form.Item>
								<Form.Item label="车牌号码" name="carNumber">
									<Input autoComplete="off" placeholder="请输入车牌号码" allowClear maxLength={8} />
								</Form.Item>
								<Form.Item label="城市" name="city">
									<Select style={{width: 140}} allowClear showSearch optionFilterProp="children" placeholder="请选择城市">
										{city.map(item => (
											<Select.Option key={item.cityCode} value={item.cityName}>{item.cityName}</Select.Option>
										))}
									</Select>
								</Form.Item>

							</Space>
						</div>
						<div className="shrink">
							<Form.Item className="shrink">
								<div className="filter-form-control">
									<Button type="primary" htmlType="submit">
										查询
									</Button>
									<Button
										style={{margin: '0 15px'}}
										onClick={resetFilter}
									>
										重置
									</Button>
								</div>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="table-layout">
				<TableView
					ref={viewTable}
					fetchOption={{url: '/driver/pageDriverInfo', method: 'post'}}
					columns={columns}
				/>
			</div>
		</div>
	)
}