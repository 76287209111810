import {Image} from "antd";
import React from "react";
let { PreviewGroup } = Image

export default function (props){
	let { width = 200, srcList = [], ...imageAttr } = props
	let boxStyle = {
		width: width+'px',
		height: width+'px',
		display: 'inline-block',
		position: 'relative',
		overflow: 'hidden',
	}
	let imgStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: width+'px',
	}
	return (
		<PreviewGroup>
			{
				srcList.map((item, index) => (
					<div
						key={item}
						style={{display: 'inline-block', border: 'solid 1px #b5b1b1', marginRight: '20px', padding: '10px', 	marginBottom: '20px',}}
					>
						<div style={boxStyle}>
							<div style={imgStyle}>
								<Image {...imageAttr} src={item} />
							</div>
						</div>
					</div>
				))
			}
		</PreviewGroup>

	)
}