import React, {useEffect, useState, useRef} from "react";
import { useHistory } from 'react-router-dom';
import TableView from "@/src/components/view/table"
import { tableColumns } from './tableConfig'
export default function Page({dataInfo}){
	let history = useHistory();
	/*表格*/
	const viewTable = useRef(null);
	function tableAction(row, type){
		history.push(`/home/driver/detail?driverId=${row.userId}`)
	}
	let [columns, setColumns] = useState([])
	useEffect(() => {
		setColumns(tableColumns(tableAction))
		getTableData()
	}, [])

	async function getTableData(params){
		let ps = {
			userId: dataInfo.userId,
			type: 1
		}
		viewTable.current.reLoad(ps)
	}
	return (
		<div>
			<div className="table-layout">
				<TableView
					ref={viewTable}
					fetchOption={{url: '/order/pagePersonalOrders', method: 'post'}}
					columns={columns}
					rowKey="sn"
				/>
			</div>
		</div>
	)
}