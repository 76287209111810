import React, {useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {Button, Col, Radio, DatePicker, Form, Input, message, Modal, Row, Space} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import {getDict} from "@/src/store/config";
import TableView from "@/src/components/view/table/index"
import ModalView from "@/src/components/view/modal/index"
import TextList from '@/src/components/view/textList'
import { tableColumns } from './tableConfig'
import { fetch } from '@/src/utils/request.js'
import {formatDate} from "@/src/utils";
import moment from "moment";
import {ExclamationCircleOutlined} from '@ant-design/icons';
const { confirm } = Modal;

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
// 不可选择的时候
function disabledDate(current) {
	return current && current > moment().endOf('day');
}
export default function List(){
	const dispatch = useDispatch()

	/*筛选*/
	const [filterForm] = Form.useForm();
	function resetFilter(){
		filterForm.resetFields();
		getTableData(filterForm.getFieldValue())
	}
	/*审批*/
	const [checkResult, setCheckResult] = useState(1);
	const onCheckResultChange = (e) => {
		setCheckResult(e.target.value);
	};
	/*loading*/
	const [loading, setLoading] = useState(false)
	/*表格*/
	const viewTable = useRef(null);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRowKeys(selectedRowKeys)
		},
	};
	function getRenderListField(expect = []){
		const result = []
		columns.forEach(item => {
			const { title, dataIndex } = item
			if(expect.includes(title)){
				return
			}
			const res = {
				...item,
				label: title,
				value: dataIndex
			}
			result.push(res)
		})
		setDataList(result)
	}
	async function handleDes(row){
		const { err, data } = await fetch({
			url: '/pos-admin/pay/getReceiverRecordDetail',
			params: {
				tranId: row.id
			}
		})
		if(err) return
		getRenderListField(['操作'])
		setRowInfo({ ...row, ...data.data})
		setModalOpen(true)
	}
	function batchCheck(){
		handleCheck(selectedRowKeys)
	}
	function todoHandleCheckItem(data){
		getRenderListField(['完成时间', '操作'])
		setRowInfo(data)
		setCheckModalOpen(true)
	}
	function doHandleCheck(){
		if(checkResult === 0){
			setCheckModalOpen(false)
			return
		}
		approveWithdraw([rowInfo.id])
	}
	function handleCheck(tranIds = []){
		confirm({
			title: '提示',
			icon: <ExclamationCircleOutlined />,
			content: '确定审核通过吗？',
			async onOk() {
				approveWithdraw(tranIds)
			},
			onCancel() {},
		})
	}
	async function approveWithdraw(tranIds){
		setLoading(true)
		const { err } = await fetch({
			url: '/pos-admin/pay/approveWithdraw',
			params: {
				tranIds: tranIds.join(',')
			}
		})
		setLoading(false)
		if(!err){
			message.success('审批成功');
			setCheckModalOpen(false)
			getTableData(filterForm.getFieldValue())
		}
		return err
	}
	function tableAction(row, type){
		if(type === 'des'){
			handleDes(row)
		} else if(type === 'check'){
			todoHandleCheckItem(row)
		} else if(type === 'refresh'){
			refreshOrder(row.id)
		} else if(type === 'cost'){
			todoCostOrder(row)
		}
	}
	async function todoCostOrder(row){
		getRenderListField(['操作'])
		setRowInfo(row)
		setCheckCostOpen(true)
	}
	function batchRefresh() {
		refreshOrder(selectedRowKeys.join(','))
	}
	async function refreshOrder(tranIds){
		setLoading(true)
		const { err } = await fetch(
			{
				url: '/pos-admin/pay/manualProfitSharingResult',
				method: 'post',
				data: {
					tranIds
				}
			},
			{
				formData: true
			}
		)
		setLoading(false)
		if(!err){
			message.success('刷新成功');
			getTableData(filterForm.getFieldValue())
		}
	}
	let columns = tableColumns(tableAction)
	useEffect(async () => {
		await dispatch(getDict(2))
		getTableData(filterForm.getFieldValue())
	}, [])
	async function getTableData(params){
		setSelectedRowKeys([])
		let ps = {
			...params,
		}
		if(ps.tradeTime && ps.tradeTime.length){
			ps.startDate = ps.tradeTime[0].format(dateFormat)
			ps.endDate = ps.tradeTime[1].format(dateFormat)
		}
		delete ps.tradeTime
		viewTable.current.reLoad(ps)
	}
	function filterData(data){
		return {
			current: 1,
			records: data,
			size: 10,
			total: data.length,
		}
	}
	/*Modal*/
	let [isModalOpen, setModalOpen] = useState(false)
	let [isCheckModalOpen, setCheckModalOpen] = useState(false)
	let [isCheckCostOpen, setCheckCostOpen] = useState(false)
	let [rowInfo, setRowInfo] = useState({})
	let [dataList, setDataList] = useState([])
	async function doHandleCost(){
		setLoading(true)
		const { err, data } = await fetch({
			url: '/pos-admin/pay/manualProfitSharing',
			method: 'post',
			data: {
				tranIds: rowInfo.id
			}
		})
		setLoading(false)
		if(!err){
			setCheckCostOpen(false)
			message.success('分帐成功');
		}
	}
	function handleCancel(){
		setModalOpen(false)
	}
	return (
		<div>
			<div className="filer-layout">
				<Form
					form={filterForm}
					layout="inline"
					onFinish={getTableData}
					initialValues={{dateType: 1, mobileType: 2}}
				>
					<div className="flex">
						<div className="flex-1 relative">
							<Space size={[8, 16]} wrap>
								<Form.Item name="tradeTime" label="支付时间">
									<RangePicker
										format={dateFormat}
										disabledDate={disabledDate}
										ranges={{
											'当月': [moment().startOf('month'), moment()],
											'近三个月': [moment().subtract(3, 'month'), moment()],
											'近六个月': [moment().subtract(6, 'month'), moment()],
										}}
									/>
								</Form.Item>
							</Space>
						</div>
						<div className="shrink">
							<Form.Item className="shrink">
								<div className="filter-form-control">
									<Button type="primary" htmlType="submit">
										查询
									</Button>
									<Button
										style={{margin: '0 15px'}}
										onClick={resetFilter}
									>
										重置
									</Button>
								</div>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div style={{padding: '6px 0'}}>
				<Space size="middle">
					<Button loading={loading} type="primary" onClick={batchCheck} disabled={!selectedRowKeys.length}>
						审批
					</Button>
					<Button loading={loading} type="primary" onClick={batchRefresh} disabled={!selectedRowKeys.length}>
						手工刷新
					</Button>
				</Space>
			</div>
			<div className="table-layout">
				<TableView
					showIndex
					filterResponse={filterData}
					ref={viewTable}
					fetchOption={{url: '/pos-admin/pay/getWithdrawRecordsAll', method: 'get'}}
					columns={columns}
					rowSelection={{
						type: 'checkbox',
						...rowSelection,
						columnWidth: '60px'
					}}
				/>
			</div>
			<ModalView title="提现明细" visible={isModalOpen} isLook onCancel={handleCancel}>
				<Row>
					<Col span={20}>
						{TextList({ dataList, dataSource: rowInfo, labelStyle: {width: '130px'} })}
					</Col>
				</Row>
				<div className="text-title">金额详细：</div>
				{
					rowInfo.recordsDetailsList && rowInfo.recordsDetailsList.map(info => (
						<div>
							<div className="flex align-center">
								<view className="label" style={{color: '#666'}}>订单时间：</view>
								<view className="value">{formatDate(info.applyDate)}</view>
								<view className="label" style={{marginLeft: '20px', color: '#666'}}>费用类型：</view>
								<view className="value">{info.type}</view>
								<view className="label" style={{marginLeft: '20px', color: '#666'}}>金额：</view>
								<view className="value">{(info.amount||0)/100}</view>
								<view className="label" style={{marginLeft: '20px', color: '#666'}}>订单号：</view>
								<view className="value">{info.orderSn}</view>
							</div>
							<div className="flex align-center">
								<view className="label" style={{marginLeft: '20px', color: '#666'}}>备注：</view>
								<view className="value">{info.remark}</view>
							</div>
						</div>
					))
				}
			</ModalView>
			<ModalView
				title="审批"
				visible={isCheckModalOpen}
				onCancel={()=> setCheckModalOpen(false)}
				onConfirm={doHandleCheck}
				loading={loading}
			>
				<Row>
					<Col span={20}>
						{TextList({ dataList, dataSource: rowInfo, labelStyle: {width: '130px'} })}
						<div className="flex align-center">
							<view className="label" style={{color: '#666'}}>是否审批：</view>
							<view className="value">
								<Radio.Group onChange={onCheckResultChange} value={checkResult}>
									<Radio value={1}>通过</Radio>
									<Radio value={0}>不通过</Radio>
								</Radio.Group>
							</view>
						</div>
					</Col>
				</Row>
			</ModalView>
			<ModalView
				title="立即分帐"
				visible={isCheckCostOpen}
				onCancel={()=> setCheckCostOpen(false)}
				onConfirm={doHandleCost}
				loading={loading}
				footer={[
					[
						<Button key="back" onClick={()=> setCheckCostOpen(false)} loading={loading}>
							取消
						</Button>,
						<Button key="submit" type="primary" onClick={doHandleCost} loading={loading}>
							立即分帐
						</Button>
					]
				]}
			>
				<Row>
					<Col span={20}>
						{TextList({ dataList, dataSource: rowInfo, labelStyle: {width: '130px'} })}
					</Col>
				</Row>
			</ModalView>
		</div>
	)
}
