import { fetch } from "@/src/utils/request";

export async function getClientInfo(userId){
	return fetch({
		url: '/client/detailClientInfo',
		params: {
			userId
		},
		method: 'post'
	})
}