import React, {useEffect,  useState} from 'react'
import { fetch } from '@/src/utils/request.js'
import {formatDate, hidePhone} from '@/src/utils/index'
import moment from 'moment';
import ModalContent from './Modal'
import {
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Button,
  Table,
  Tag,
  Modal,
} from 'antd';
const { RangePicker } = DatePicker;


const dateFormat = 'YYYY-MM-DD';
// 表格默认值
const initialFormValues = {
  rangeDate: [moment(moment().subtract(1, 'month'), dateFormat), moment(new Date(), dateFormat)]
}
export const auditStatusMap = {
  0: { color: '#666', name: '未认证' },
  1: { color: 'geekblue', name: '待认证' },
  2: { color: 'volcano', name: '认证失败' },
  3: { color: 'green', name: '已认证' },
}
// 不可选择的时候
function disabledDate(current) {
  return current && current > moment().endOf('day');
}
function getColumns({showModal}){
  return [
    {
      title: '司机编号',
      dataIndex: 'id',
      width: 100,
      key: 'id',
      render: text => <span>{text}</span>,
    },
    {
      title: '司机姓名',
      dataIndex: 'nickname',
      width: 100,
      key: 'nickname',
    },
    {
      title: '司机手机号',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 100,
      render: hidePhone
    },
    {
      title: '所在城市',
      dataIndex: 'city',
      key: 'city',
      width: 100,
    },
    {
      title: '注册时间',
      width: 200,
      dataIndex: 'createAt',
      key: 'createAt',
      render: text => <span>{formatDate(text)}</span>,
    },
    {
      title: '认证状态',
      key: 'auditStatus',
      width: 100,
      dataIndex: 'auditStatus',
      render: auditStatus => {
        let status = auditStatusMap[auditStatus || 0]
        return (
          <>
            <Tag color={status.color} key={auditStatus}>
              {status.name}
            </Tag>
          </>
        )
      },
    },
    {
      title: '申请认证时间',
      dataIndex: 'applyTime',
      width: 200,
      key: 'applyTime',
      render: text => <span>{formatDate(text)}</span>,
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <Space size="middle">
          <span onClick={() => showModal(record, 'look')} className="color-4a67de pointer">查看</span>
          {
            record.auditStatus === 1 && <span onClick={() => showModal(record)} className="color-FF681D pointer">审核</span>
          }
        </Space>
      ),
    },
  ];
}
function Page(){
  let [isLoading, setLoading] = useState(false)
  // 保存列表数据
  let [dataList, setDataList] = useState([])
  // 编辑或查看的数据
  let [editData, setEditData] = useState({})
  // 弹层
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = (data, type) => {
    setEditData({
      ...data,
      isEdit: !type
    })
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function closeModal(_force){
    handleCancel()
    if(!!_force){
      getData()
    }
  }
  // 表格
  const columns = getColumns({showModal})
  // 筛选表单
  const [filterForm] = Form.useForm();
  // 分页
  let [pageInfo, setPageInfo] = useState({
    current: 1,
    pageSize: 15,
    total: 0
  })
  function onShowSizeChange(current, size){
    setPageInfo({
      ...pageInfo,
      pageSize: size
    })
  }
  function onChange(e){
    setPageInfo({
      ...pageInfo,
      current: e
    })
    getData()
  }
  // 获取列表数据
  useEffect(() => {
    getData()
  }, [])
  async function getData(){
    let filter = filterForm.getFieldValue()
    const params = {
      ...pageInfo,
      ...filter,
      size: pageInfo.pageSize,
      beginTime: filter.rangeDate[0].format(dateFormat) + ' 00:00:00',
      endTime: filter.rangeDate[1].format(dateFormat) + ' 23:59:59',
    }
    delete params.rangeDate
    setLoading(true)
    const {err, data} = await fetch({
      url: '/driverAudit/listPageDrivers',
      method: 'post',
      data: params
    })
    setLoading(false)
    if(!err){
      const { records, total } = data.data
      setPageInfo({
        ...pageInfo,
        total
      })
      setDataList( params.current === 1 ? records : [ ...dataList, ...records ])
    }
  }
  return (
    <div className="driver-layout">
      <div className="filer-layout">
        <Form
          form={filterForm}
          layout="inline"
          onFinish={getData}
          initialValues={initialFormValues}
        >
          <div className="flex">
            <div className="flex-1 relative">
              <Space size={[8, 16]} wrap>
                <Form.Item label="司机姓名" name="nickname">
                  <Input autoComplete="off" placeholder="请输入司机姓名" allowClear />
                </Form.Item>
                <Form.Item label="司机电话"  name="mobile">
                  <Input autoComplete="off" placeholder="请输入司机电话" allowClear />
                </Form.Item>
                <Form.Item label="认证状态" name="auditStatus">
                  <Select style={{ width: 140 }} allowClear placeholder="请选择认证状态">
                    <Select.Option value="3">已认证</Select.Option>
                    <Select.Option value="1">待认证</Select.Option>
                    <Select.Option value="2">认证失败</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="申请认证时间" name="rangeDate">
                  <RangePicker format={dateFormat} disabledDate={disabledDate}/>
                </Form.Item>
              </Space>
            </div>
            <Form.Item className="shrink">
              <div className="filter-form-control">
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button
                  style={{ margin: '0 15px' }}
                  onClick={() => {
                    filterForm.resetFields();
                    getData()
                  }}
                >
                  重置
                </Button>
              </div>

            </Form.Item>
          </div>

        </Form>
      </div>
      <div className="table-layout">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={dataList}
          rowKey="id"
          scroll={{ x: 1000 }}
          pagination={
            {
              ...pageInfo,
              total: pageInfo.total,
              showTotal: (total, range) => `共 ${total} 条`,
              showSizeChanger: true,
              onChange: onChange,
              onShowSizeChange: onShowSizeChange,
            }
          }
        />

      </div>
      <>
        <Modal
          bodyStyle={{padding: 0}}
          width="1000px"
          title={editData.isEdit ? '审核司机' : '查看司机详情'}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          destroyOnClose
        >
          <ModalContent closeModal={closeModal}  data={editData}></ModalContent>
        </Modal>
      </>
    </div>
  )
}
export default Page
