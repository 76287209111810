import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {getDict} from "@/src/store/config";
import './driver.less'
import {
  Form,
  Row,
  Col,
  Checkbox,
  Radio,
  Image,
  Button,
  Modal,
  Input,
  Tag,
  message,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {formatDate} from '@/src/utils';
import { emptyImg } from '@/src/utils/helper'
import { fetch } from '@/src/utils/request';
import { auditStatusMap } from './index'
const { confirm } = Modal;

let modalLabelLayout = { span: 7 }
let modalValueLayout = { span: 17 }
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
let imaLabel = {
  color: '#999',
  fontSize: '13px',
  marginTop: '5px',
  width: '200px'
}
export default function ModalContent({closeModal, data}){
  const { dict } = useSelector(state => state.config);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getDict(7))
    dispatch(getDict(5))
    dispatch(getDict(1))
  },[])
  let isLook = !data.isEdit
  let [isLoading, setLoading] = useState(false)
  // 不通过原因
  let [showReason, setShowReason] = useState(isLook && data.auditStatus === 2) // 查看状态下且是不通过状态则默认显示错误
  let [showCustomerArea, setShowCustomerArea] = useState(false)
  let [showReasonErr, setShowReasonErr] = useState(false)
  // 表单
  const [modalForm] = Form.useForm();
  function passSubmit(){
    confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确定审核通过吗？',
      onOk() {
        submitAudit(true)
      },
      onCancel() {},
    })
  }
  function noPassSubmit(){
    setShowReason(true)
    let valid = validFailReason()
    modalForm.validateFields()
      .then(res => {
        if(!valid) return
        submitAudit(false)
      })
      .catch(err => {
        console.log('err', err)
      })
  }
  function validFailReason(){
    let { reasonGroup = [], customerReason } = modalForm.getFieldValue()
    // 只勾选了其它原因，需要填写其它原因
    if(reasonGroup.length === 1 && reasonGroup[0].dictCode === 10 && !customerReason){
      setShowReasonErr(true)
      return false
    }
    if(reasonGroup.length){
      setShowReasonErr(false)
      return true
    }
    setShowReasonErr(true)
    return false
  }
  async function submitAudit(isPass){
    let params = {}
    if(isPass){
      params = {
        id: data.id,
        auditStatus: 3,
        carPicFrontStatus: 3,
        carPicSideStatus: 3,
        drivingLicenseFrontStatus: 3,
        drivingLicenseVersoStatus: 3,
      }
    } else {
      let formData = modalForm.getFieldsValue()
      let auditFailedReasons = []
      formData.reasonGroup.forEach(item => {
        auditFailedReasons.push({
          dictCode: item.dictCode,
          content: item.dictCode == 10 ? formData.customerReason: ''
        })
      })
      params = {
        id: data.id,
        auditStatus: 2,
        auditFailedReasons: JSON.stringify(auditFailedReasons),
        carPicFrontStatus: formData.carPicFrontStatus,
        carPicSideStatus: formData.carPicSideStatus,
        drivingLicenseFrontStatus: formData.drivingLicenseFrontStatus,
        drivingLicenseVersoStatus: formData.drivingLicenseVersoStatus,
      }
    }
    setLoading(true)
    const {err} = await fetch({
      url: '/carAudit/auditCar',
      method: 'post',
      data: params
    })
    setLoading(false)
    if(!err){
      message.success(isPass ? '已通过': '已拒绝')
      closeModal(true)
    }

  }
  // 表单的值发生了变化
  function valuesChange(){
    let data = modalForm.getFieldValue()
    console.log('data', data)
    const { reasonGroup } = data
    let dictCodeIs6 = reasonGroup&&reasonGroup.find(item => item.dictCode === 10) // 其它原因
    setShowCustomerArea(!!dictCodeIs6)
  }
  let status = auditStatusMap[data.auditStatus || 0]
  let reasonList = dict[7] || []
  // 查看时的不通过原因
  const failedReason = []
  if(reasonList.length && data.failedReason){
    let reasonMap = {}
    reasonList.forEach(item => {
      reasonMap[item.dictCode] = {...item}
    })
    let getFailedReason = JSON.parse(data.failedReason)||[]
    getFailedReason.forEach(item => {
      let curI = reasonMap[item.dictCode]
      if(curI){
        failedReason.push({
          ...curI,
          content: item.content || curI.dictName
        })
      }
    })
  }
  // 获取特殊规格
  let specifications = dict[1]
  let curSpecification = ''
  if(specifications){
    let specialSpecifi = data.specialSpecifi.split('&&')
    let result = []
    specifications.forEach(item => {
      if(specialSpecifi.includes(''+item.dictCode)){
        result.push(item.dictName)
      }
    })
    curSpecification = result.join('、')
  }
  // 获取车厢类型
  let vanTypeList = dict[5]
  let curVanType = ''
  if(vanTypeList){
    let specialSpecifi = vanTypeList.find(item => item.dictCode == data.vanType)
    curVanType = specialSpecifi&&specialSpecifi.dictName
  }
  return (
    <>
      <div  className="ant-modal-body">
        <div className="modal-title">车辆信息：</div>
        <div className="modal-content">
          <Row>
            <Col span={12}>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">认证状态：</div></Col>
                <Col {...modalValueLayout}>
                  <div className="modal-value">
                    <Tag color={status.color}>
                      {status.name}
                    </Tag>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">车辆类型：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{data.typeName}</div></Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">车厢类型：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{curVanType}</div></Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">车牌颜色：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{data.carColor}</div></Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">车牌号码：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{data.carNumber}</div></Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">车辆识别代号：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{data.vin}</div></Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">行驶证所有人：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{data.drivingLicenseOwner}</div></Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">申请认证时间：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{formatDate(data.applyTime)}</div></Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">车身尺寸：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{data.lwh} <small>(长宽高)</small> </div></Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">车辆载重：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{data.loadKg}</div></Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">车辆体积：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{data.loadVol}</div></Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">特殊规格：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{curSpecification}</div></Col>
              </Row>
              <Row>
                <Col {...modalLabelLayout}><div className="modal-label">行驶证所有人类型：</div></Col>
                <Col {...modalValueLayout}><div className="modal-value">{data.drivingLicenseOwnerType}</div></Col>
              </Row>

            </Col>
          </Row>
        </div>
        <Form
          {...layout}
          form={modalForm}
          name="basic"
          initialValues={{
            carPicFrontStatus: data.carPicFrontStatus || '',
            carPicSideStatus: data.carPicSideStatus || '',
            drivingLicenseFrontStatus: data.drivingLicenseFrontStatus || '',
            drivingLicenseVersoStatus: data.drivingLicenseVersoStatus || '',
          }}
          onValuesChange={valuesChange}
        >
          <div className="modal-title m-b">附件信息：</div>
          <div className="modal-content m-b">
            <Row>
              <Col span={6}>
                <div className="adapt-img-box">
                  <Image
                    src={data.drivingLicenseFront}
                    fallback={emptyImg}
                  />
                </div>
                <div style={imaLabel}>（ 行驶证正面照 ）</div>
                {
                  showReason&&
                  <Form.Item
                    wrapperCol={24}
                    name="drivingLicenseFrontStatus"
                    rules={[
                      {
                        validator: async (rule, value) => {
                          if (!(value === 2 || value === 3)) {
                            throw new Error('请选择结果');
                          }
                        }
                      }
                    ]}
                  >
                    <Radio.Group disabled={isLook}>
                      <Radio value={3}>通过</Radio>
                      <Radio value={2}>不通过</Radio>
                    </Radio.Group>
                  </Form.Item>
                }

              </Col>
              <Col span={6}>
                <div className="adapt-img-box">
                  <Image
                    fallback={emptyImg}
                    src={data.drivingLicenseVerso}
                  />
                </div>
                <div style={imaLabel}>（ 行驶证反面照 ）</div>
                {
                  showReason&&
                  <Form.Item
                    name="drivingLicenseVersoStatus"
                    wrapperCol={24}
                    rules={[
                      {
                        validator: async (rule, value) => {
                          if (!(value === 2 || value === 3)) {
                            throw new Error('请选择结果');
                          }
                        }
                      }
                    ]}
                  >
                    <Radio.Group disabled={isLook}>
                      <Radio value={3}>通过</Radio>
                      <Radio value={2}>不通过</Radio>
                    </Radio.Group>
                  </Form.Item>
                }
              </Col>
              <Col span={6}>
                <div className="adapt-img-box">
                  <Image
                    src={data.carPicFront}
                    fallback={emptyImg}
                  />
                </div>
                <div style={imaLabel}>（ 车身正面照 ）</div>
                {
                  showReason&&
                  <Form.Item
                    wrapperCol={24}
                    name="carPicFrontStatus"
                    rules={[
                      {
                        validator: async (rule, value) => {
                          if (!(value === 2 || value === 3)) {
                            throw new Error('请选择结果');
                          }
                        }
                      }
                    ]}
                  >
                    <Radio.Group disabled={isLook}>
                      <Radio value={3}>通过</Radio>
                      <Radio value={2}>不通过</Radio>
                    </Radio.Group>
                  </Form.Item>
                }
              </Col>
              <Col span={6}>
                <div className="adapt-img-box">
                  <Image
                    src={data.carPicSide}
                    fallback={emptyImg}
                  />
                </div>
                <div style={imaLabel}>（ 车身侧面照 ）</div>
                {
                  showReason&&
                  <Form.Item
                    wrapperCol={24}
                    name="carPicSideStatus"
                    rules={[
                      {
                        validator: async (rule, value) => {
                          if (!(value === 2 || value === 3)) {
                            throw new Error('请选择结果');
                          }
                        }
                      }
                    ]}
                  >
                    <Radio.Group disabled={isLook}>
                      <Radio value={3}>通过</Radio>
                      <Radio value={2}>不通过</Radio>
                    </Radio.Group>
                  </Form.Item>
                }
              </Col>
            </Row>
          </div>
          {
            showReason && <>
              <div className="modal-title m-b">拒绝原因：</div>
              {
                isLook
                  ? (
                    <div className="modal-content">
                      {(failedReason || []).map((item, index) => (
                        <Row key={index}>
                          <Col span={24}>
                            <div style={{'lineHeight': '32px'}}>{index+1}、{item.content}</div>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  )
                  : (
                    <div className="modal-content">
                      <Form.Item  style={{marginBottom: 0}} name="reasonGroup">
                        <Checkbox.Group>
                          {reasonList.map((item, index) => (
                            <Row key={index}>
                              <Col span={24}>
                                <Checkbox value={item} style={{ lineHeight: '32px' }}>
                                  {item.dictName}
                                </Checkbox>
                              </Col>
                            </Row>
                          ))}
                        </Checkbox.Group>
                      </Form.Item>
                      {
                        showCustomerArea &&
                        <Form.Item name="customerReason" noStyle>
                          <Input.TextArea rows={4} maxLength="60" showCount  placeholder="请填写详细的拒绝原因" />
                        </Form.Item>
                      }
                    </div>
                  )
              }
            </>
          }
          {showReasonErr&&
            <Form.Item>
              <div className="ant-form-item-explain ant-form-item-explain-error" style={{paddingLeft: '15px'}}>请选择或填写拒绝原因</div>
            </Form.Item>
          }
        </Form>
      </div>
      {
        !isLook
          ? (
            <div className="ant-modal-footer">
              <Button type="primary" onClick={passSubmit} loading={isLoading}>
                审核通过
              </Button>
              <Button type="primary"  onClick={noPassSubmit} style={{ marginLeft: '15px' }} loading={isLoading}>
                拒绝
              </Button>
              <Button style={{ margin: '0 15px' }} loading={isLoading} onClick={closeModal}>
                取消
              </Button>
            </div>
          )
          :(
            <div className="ant-modal-footer">
              <Button type="primary" onClick={closeModal}>
                返回
              </Button>
            </div>
          )
      }
    </>
  );
}