import {formatDate, isU} from "@/src/utils";
import {invalidTag, statusMapEnum} from "@/src/utils/helper";

import React from "react";
import LinkText from "@/src/components/LinkText";

export const tableColumns  = function (doAction){
	return [
		{
			title: '城市',
			dataIndex: 'city',
			width: 100,
		},
		{
			title: '订单号',
			dataIndex: 'sn',
			width: 150,
			render: (text) => (<LinkText link={'/home/driver/orderDetail?sn='+text}>{text}</LinkText>)
		},
		{
			title: '下单时间',
			dataIndex: 'createAt',
			width: 100,
			render: (createAt) => (<div>{formatDate(createAt)}</div>)
		},
		{
			title: '应收金额',
			dataIndex: 'amount',
			width: 120,
			render: (text) => (<div>{isU(text) ? invalidTag: text/100}</div>)
		},
		{
			title: '实收金额',
			dataIndex: 'actualAmount',
			width: 120,
			render: (text) => (<div>{isU(text) ? invalidTag: text/100}</div>)
		},
		{
			title: '订单状态',
			dataIndex: 'status',
			width: 200,
			render: text => <span>{statusMapEnum[text]|| invalidTag}</span>,
		},
		{
			title: '用户ID',
			dataIndex: 'userId',
			width: 200,
			render: (text) => (isU(text) ? invalidTag: <LinkText link={'/home/driver/orderDetail?sn='+text}>{text}</LinkText>)
		},
		{
			title: '接单时间',
			dataIndex: 'verifyTime',
			width: 200,
			render: text => <span>{text ? formatDate(text): invalidTag}</span>,
		},
		{
			title: '拒单时间',
			dataIndex: 'cancelTime',
			width: 200,
			render: text => <span>{text ? formatDate(text): invalidTag}</span>,
		},
		{
			title: '评分',
			dataIndex: 'score',
			width: 200,
			render: text => <span>{isU(text) ? invalidTag: text/10 }</span>,
		},
		{
			title: '支付方式',
			dataIndex: 'paymentName',
			width: 200,
		},
	];
}