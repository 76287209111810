import React, { useState } from 'react';
import { Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { arrToObj } from '../utils/index'
import { menuStackContext } from '../context/index'
import { HomeRouter } from '@/src/route'
const { SubMenu } = Menu;

function parsePath(location){
  let menuObk = arrToObj(HomeRouter, 'path', 'routes')
  let openKeys = []
  let labelStack = []
  let { pathname } = location
  let curParent = menuObk[pathname]
  curParent&&labelStack.push(curParent)
  while (curParent && curParent.parent){
    let curLink = curParent.parent.path
    openKeys.push(curLink)
    curParent = menuObk[curLink]
    curParent&&labelStack.push(curParent)
  }
  return {
    openKeys,
    labelStack
  }
}
function SubMenuList(list){
  return (
    <SubMenu key={list.path} icon={list.icon||''} title={list.label}>
      {MenuList(list.routes)}
    </SubMenu>
  )
}
function MenuList(list){
  return (
    list.filter(item => !item.passive).map((item) => (
      !item.routes
        ? <Menu.Item key={item.path} icon={item.icon||''}>{item.label}</Menu.Item>
        : SubMenuList(item)
    ))
  )
}

export default function AppMenu(){
  let location = useLocation();
  let history = useHistory();
  let [defaultOpenKeys, setDefaultOpenKeys] = useState([])
  // let { openKeys: defaultOpenKeys } = parsePath(location) // 设置菜单选中
  React.useEffect(() => {
    let { openKeys } = parsePath(location) // 设置菜单选中
    setDefaultOpenKeys(openKeys)
  }, [])
  // 保存当前菜单中层级
  const [_, setMenuStack] = React.useContext(menuStackContext)
  React.useEffect(() =>{
    let { labelStack=[] } = parsePath(location)
    setMenuStack(labelStack.reverse())
  }, [location])
  // 切换路由
  function handleClick(e) {
    history.push(e.key)
  }
  function onOpenChange(key){
    setDefaultOpenKeys(key)
  }
  return (
      <Menu
      onClick={handleClick}
      style={{ width: '100%' }}
      defaultSelectedKeys={[location.pathname]}
      openKeys={defaultOpenKeys}
      onOpenChange={onOpenChange}
      mode="inline"
      theme="dark"
    >
      {MenuList(HomeRouter)}
    </Menu>
  );
}
