import {formatDate, hidePhone} from "@/src/utils";
import React from "react";
import {Space, Tooltip} from 'antd'

const prepayValidStatusMap = {
	1: '未支付',
	2: '已支付',
}
const statusMap = {
	1: '未支付',
	2: '已支付',
}
function CellToolTip(props){
	return (
		<Tooltip title={props.children}><div className="text-ellipsis"> {props.children}</div></Tooltip>
	)
}
export const tableColumns  = function (doAction){
	return [
		{
			title: '提现申请时间',
			dataIndex: 'filingDate',
			nowrap: true,
			width: 140,
			render: (createAt) => (<div className="nowrap">{formatDate(createAt)}</div>)
		},
		{
			title: '提现流水号',
			dataIndex: 'identifierCode',
			width: 180
		},
		{
			title: '提现人/手机号',
			dataIndex: 'mobile',
			width: 150,
			render: (val, record) => (<div className="nowrap">{record.name}/{record.mobile}</div>)
		},
		{
			title: '提现金额',
			dataIndex: 'totalAmount',
			width: 120,
			render: (val) => (<div className="nowrap">{(val||0)/100}</div>)
		},
		{
			title: '状态',
			dataIndex: 'statusCn',
			nowrap: true,
		},
		{
			title: '是否审批',
			dataIndex: 'chackCn',
			nowrap: true
		},
		{
			title: '备注',
			dataIndex: 'remark',
			width: 150
		},
		{
			title: '完成时间',
			dataIndex: 'doneTime',
			nowrap: true,
			width: 140,
			render: (createAt) => (<div className="nowrap">{formatDate(createAt)}</div>)
		},
		{
			title: '操作',
			fixed: 'right',
			width: 200,
			render: (text, record) => (
				<Space size="middle">
					{record.status === 1 && <span onClick={() => doAction(record, 'check')} className="color-4a67de pointer">审批</span>}
					{record.status === 2 && <span onClick={() => doAction(record, 'cost')} className="color-4a67de pointer">分帐</span>}
					{record.status === 3 && <span onClick={() => doAction(record, 'refresh')} className="color-4a67de pointer">刷新</span>}
					<span onClick={() => doAction(record, 'des')} className="color-4a67de pointer">明细</span>
				</Space>
			),
		},
	];
}
