import axios from 'axios'
import { message } from 'antd';
import { createBrowserHistory } from 'history';
import { toFormData } from './index';
// 判断当前运行环境
let devEvn = 'pro'
let leg = new RegExp(".?pos-admin-(.+).(\\pos|\\pos)", "g")
if(window.location.host.indexOf('pos.cn') > 0 || window.location.host.indexOf('pos.com') > 0){ // 如果是线上访问
	let result = leg.exec(window.location.host)
	devEvn = result ? result[1]: 'pro'
} else { // 如果是本地访问
	let proxy = require('../proxy')
	let legApi = new RegExp(".?pos-(.+).(\\pos|\\pos)", "g")
	if(proxy && proxy["/api"]){
		let result = legApi.exec(proxy["/api"].target)
		devEvn = result ? result[1]: 'pro'
	}
}
// api base
/*export const baseUrl = {
	local: 'http://10.129.35.47',
	dev: '//www.mslahuo.cn:9800',
	stg: '//www.mslahuo.cn:9800',
	pro: '//www.mslahuo.cn:9800',
}*/
export const baseUrl = window.globalBseUrl
console.log('baseUrl', baseUrl)
const PREFIX_MAP_SERVICE = {
	'dict': 'pos-main',
	'city': 'pos-main',
	'configCarType': 'pos-main',
}
function getApiUrl(url){
	let base = url.substring(1)
	base = base.substring(0, base.indexOf('/'))
	// const service = PREFIX_MAP_SERVICE[base] || 'pos-admin'
	// const service = '9800'
	// return `${baseUrl['dev']}`
	return window.location.host.indexOf('localhost') === 0 ? `/api`: `${baseUrl['dev']}`
}

// token
export const userToken = {
	value: '',
	getValue(){
		return this.value
	},
	setValue(val){
		localStorage.setItem('pos-token', val);
		this.value = val
	}
}
let posToken = localStorage.getItem('pos-token');
if(posToken){
	userToken.setValue(posToken)
}

// http
const http = new axios.create();
http.interceptors.request.use((config) => { // 可使用async await 做异步操作
	config.baseURL = getApiUrl(config.url)
	if(config.config.formData){ // 不用显示
		console.log('formData')
		config.headers['Content-Type'] = 'multipart/form-data'
		config.data = toFormData(config.data)
	}
	if(userToken.getValue()){
		config.headers['token'] = userToken.getValue()
	}
	return config
}, err => { // 可使用async await 做异步操作
	return Promise.reject(err)
})

http.interceptors.response.use(async (response) => {
	if(response.config.noToast){ // 不用显示
		return response.data
	}
	let err = ''
	if(response.data.code === 455){
		createBrowserHistory().push('/login')
		window.location.reload()
	} else if(response.data.code === 501 || response.data.code === 202){
		 err = response.data.msg
	} else if(response.data.code === 500){
		 err = '发生未知错误'
	 } else if(response.data.code !== 200){
		 err = response.data.msg || '发生未知错误'
	}
	 if(!!err){
	 	setTimeout(() => { // 解决被hideLoading隐藏
			message.error(err);
		})
	 }
  return response.data
}, (response) => { /*  对响应错误做点什么 （statusCode !== 200）*/
	message.error('发生了请求错误');
  return Promise.reject(response)
})


export function fetch(options, config = {}){
	const { method = 'get', url, data, params} = options
	const doFetch = http.request
	return doFetch({
		method,
		url,
		data,
		params,
		config
	})
	 .then(res => {
		 return { err: res.code === 200 ? null : res, data: res }
	 })
	 .catch(err => {
		 return { err }
	 })
}
export function uploadFile(options){
	const { path, ...opt } = options
	return http.upload('/file/upload', {
		header: { "Content-Type": "multipart/form-data" },
		filePath: path,
		name: 'file',
		formData: opt
	}).then(res => {
		return { err: res.code === 200 ? null : res, data: res }
	})
		.catch(err => {
			return { err }
		})
}

export default {
	fetch
}
