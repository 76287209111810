import React, {useEffect, useState, useRef} from "react";
import { getOrderInfo } from '@/src/store/order'
import {Button, Space, Row, Col, Modal, message} from 'antd'
import queryString from 'query-string'
import TextList from "@/src/components/view/textList";
import HllModal from '@/src/components/view/modal'
import {CHANNEL_MAP, invalidTag, statusMapEnum, ENTRY_MAP} from "@/src/utils/helper";
import Desensitization from "@/src/components/view/desensitization";
import {dateYYD, formatDate, hidePhone, isU} from "@/src/utils";
import {useDispatch, useSelector} from "react-redux";
import {getCarType} from "@/src/store/config";
import LinkText from "@/src/components/LinkText";
import HllImage from '@/src/components/view/image'
import AddrPanel from "@/src/pages/driver/orderDetail/addrPanel";

function getTextList(showPriceDetail){
	return [
		{ label: '订单号', value: 'sn' },
		{ label: '订单状态', value: 'status', render: (val) => statusMapEnum[val]||invalidTag},
		{ label: '订单联系人', value: 'orderContactPhone', render: (val) => Desensitization(hidePhone(val), val), alignCenter: true},
		{ label: '下单时间', value: 'createAt', render: formatDate},
		{ label: '用车时间', value: 'presetTime', render: formatDate},
		{ label: '路线', value: 'sourceName', render: (val, data) => <AddrPanel list={data.orderMidways} />	},
		{ label: '车辆类型', value: 'carTypeName'},
		{ label: '额外需求', value: 'extraDemandExplain'},
		{ label: '订单备注', value: 'orderRemark'},
		{ label: '支付方式', value: 'paymentName'},
		{
			label: '应收金额',
			value: 'beforeChangeAmount',
			render: (text) => isU(text) ? invalidTag: <>{text/100} <Button onClick={showPriceDetail} type="primary" style={{marginLeft: '10px'}}>明细</Button></>,
			alignCenter: true
		},
		{ label: '优惠', value: 'discountAmount', render: (text) => isU(text) ? invalidTag: text/100},
		{ label: '转单', value: 'convertAmount', render: (text) => isU(text) ? invalidTag: text/100},
		{ label: '增加运费', value: 'increaseAmount', render: (text) => isU(text) ? invalidTag: text/100},
		{ label: '实收金额', value: 'amount', render: (text) => isU(text) ? invalidTag: text/100},
		{ label: '订单评分', value: 'score', render: (text) => isU(text) ? invalidTag: text/10},
		{ label: '评价内容', value: 'comment', render: (text) => text||invalidTag},
	]
}
export default function Page(props){
	const { sn } = queryString.parse(props.location.search)
	// 车型
	const { carTypeMap } = useSelector(state => state.config);
	const dispatch = useDispatch()
	// 车型 END
	// 应收金额明细
	let [showFeeModal, toggleFeeModal] = useState(false)
	let [feeInfoMenu, setFeeInfoMenu] = useState([])
	function handleCancel(){
		toggleFeeModal(false)
	}
	function showPriceDetail(){
		if(!feeInfoMenu||!feeInfoMenu.length){
			message.warn('因订单金额修改，暂无明细')
			return
		}
		toggleFeeModal(true)
	}
	function initFeeData(data){
		const { startCharge, overStepCharge, intervalStepCharges, convertAmount } = data
		if(!startCharge) return []
		setFeeInfoMenu([
			{label: '起步里程费', text: `${startCharge.price/100}元/${startCharge.drivingMeter/1000+''}km（${startCharge.totalPrice/100}元）`},
			{
				label: '里程区间费',
				render: () => (
					(intervalStepCharges&&intervalStepCharges.length)?intervalStepCharges.map((item, index) => (
						<div key={index}>
							{item.price/100}元/{item.drivingMeter/1000+''}km（{item.totalPrice/100}元）
						</div>
					)): '无'
				),
			},
			{
				label: '起里程费',
				text: (overStepCharge&&overStepCharge.price) ? `${overStepCharge.price/100}元/${overStepCharge.drivingMeter/1000+''}km（${overStepCharge.totalPrice/100}元）` : '无'
			},
			{
				label: '转单费',
				text: `${convertAmount/100}元`
			},
		])
	}
	// 应收金额明细 END
	let dataList = getTextList(showPriceDetail)
	// TextList
	let [ orderInfo, setOrderInfo ] = useState({})
	let [ driverInfoMenu, setDriverInfoMenu ] = useState([])
	let [ userInfoMenu, setUserInfoMenu ] = useState([])
	function initData(data){
		let filterData = {
			...data,
			orderRemark: [data.remark].filter(data => !!data).join(',')
		}
		setOrderInfo(filterData)
		setDriverInfoMenu([
			{
				label: '指派司机',
				value: 'assignedDriverName',
				render: (val, data) => (
					<span>{val} (ID：<LinkText link={'/home/driver/detail?driverId='+data['assignedDriverId']}>{data['assignedDriverId']}</LinkText>)</span>
				)
			},
			{
				label: '接单司机',
				value: 'driverName',
				render: (val, data) => (
					<span>{val} (ID：<LinkText link={'/home/driver/detail?driverId='+data['driverId']}>{data['driverId']}</LinkText>)</span>
				)
			},
			{label: '车牌号码', value: 'driverCarNumber',},
			{label: '接单渠道', value: 'channel', render: (text) => CHANNEL_MAP[text]},
		])
		setUserInfoMenu([
			{
				label: '下单用户',
				value: 'ordererName',
				render: (val, data) => (
					<span>
						{val} (ID：{
						data['entry'] == 2
							? <LinkText link={'/home/driver/detail?driverId='+data['driverId']}>{data['driverId']}</LinkText>
							: <LinkText link={'/home/client/detail?clientId='+data['ordererId']}>{data['ordererId']}</LinkText>
					})</span>
				)
			},
			{label: '下单渠道', value: 'entry', render: (text) => text == 2 ? '司机端': '用户端'},
		])
	}
	// TextList END
	async function fetchDrive(sn){
		let { err, data} = await getOrderInfo(sn)
		if(!err){
			initData(data.data)
			initFeeData(data.data)
		}
	}
	useEffect(async () => {
		await dispatch(getCarType())
		fetchDrive(sn)
	}, [])
	const {loadPicture, unloadPicture, orderAttachMent} = orderInfo
	let loadPictureList = loadPicture ? loadPicture.split('&&'): []
	let unloadPictureList = unloadPicture ? unloadPicture.split('&&'): []
	let orderAttachMentList = orderAttachMent || []
	return (
		<div>
			<div className="panel">
				<Row>
					<Col span={12}>
						<div className="text-title">基本资料：</div>
						{TextList({ dataList, dataSource: orderInfo })}
						<div className="text-title offset-top">接单司机：</div>
						{TextList({ dataList: driverInfoMenu, dataSource: orderInfo })}
						<div className="text-title offset-top">下单用户：</div>
						{TextList({ dataList: userInfoMenu, dataSource: orderInfo })}
					</Col>
					<Col span={12}>
						<div className="text-title">订单操作记录：</div>
						<Row wrap={false} className="text-list">
							{orderInfo.createAt&&(
								<>
									<Col>
										<div className="col-label" style={{width: '200px'}}>{formatDate(orderInfo.createAt)}</div>
									</Col>
									<Col>
										<div className="col-value">创建订单</div>
									</Col>
								</>
								)
							}
						</Row>
						<Row wrap={false} className="text-list">
							{orderInfo.callDriverTime&&(
								<>
									<Col>
										<div className="col-label" style={{width: '200px'}}>{formatDate(orderInfo.callDriverTime)}</div>
									</Col>
									<Col>
										<div className="col-value">用户致电司机</div>
									</Col>
								</>
							)
							}
						</Row>
						<Row wrap={false} className="text-list">
							{orderInfo.verifyTime&&(
								<>
									<Col>
										<div className="col-label" style={{width: '200px'}}>{formatDate(orderInfo.verifyTime)}</div>
									</Col>
									<Col>
										<div className="col-value">司机接单</div>
									</Col>
								</>
							)
							}
						</Row>
						<Row wrap={false} className="text-list">
							{orderInfo.loadTime&&(
								<>
									<Col>
										<div className="col-label" style={{width: '200px'}}>{formatDate(orderInfo.loadTime)}</div>
									</Col>
									<Col>
										<div className="col-value flex align-center">
											完成装货
											{
												loadPictureList&&loadPictureList.length&&(
													<div className="preview-box-tem">
														<i className="iconfont">&#xe703;</i>
														<div className="img-box">
															<HllImage srcList={loadPictureList} />
														</div>
													</div>
												)
											}
										</div>
									</Col>
								</>
							)
							}
						</Row>
						<Row wrap={false} className="text-list">
							{orderInfo.unloadTime&&(
								<>
									<Col>
										<div className="col-label" style={{width: '200px'}}>{formatDate(orderInfo.unloadTime)}</div>
									</Col>
									<Col>
										<div className="col-value flex align-center">
											完成卸货
											{
												unloadPictureList&&unloadPictureList.length&&(
													<div className="preview-box-tem">
														<i className="iconfont">&#xe703;</i>
														<div className="img-box">
															<HllImage srcList={unloadPictureList} />
														</div>
													</div>
												)
											}
										</div>
									</Col>
								</>
							)
							}
						</Row>
						<Row wrap={false} className="text-list">
							{orderInfo.confirmTime&&(
								<>
									<Col>
										<div className="col-label" style={{width: '200px'}}>{formatDate(orderInfo.confirmTime)}</div>
									</Col>
									<Col>
										<div className="col-value">确认收货，订单已完成</div>
									</Col>
								</>
							)
							}
						</Row>
						<div className="text-title offset-top">订单附件：</div>
						<div>
							<HllImage srcList={orderAttachMentList} />
						</div>
					</Col>
				</Row>
			</div>
			<HllModal
				title='司机应收费用明细'
				width='500px'
				visible={showFeeModal}
				onCancel={handleCancel}
				style={{top: '200px'}}
			>
				<TextList dataList={feeInfoMenu} labelStyle={{width: '100px'}} />
			</HllModal>
		</div>
	)
}