import {formatDate} from "@/src/utils";
import React from "react";

export const tableColumns  = function (doAction){
	return [
		{
			title: '用户ID',
			dataIndex: 'userId',
			key: 'userId',
			width: 100,
		},
		{
			title: '注册时间',
			dataIndex: 'createAt',
			key: 'createAt',
			width: 160,
			render: (createAt) => (<div>{formatDate(createAt)}</div>)
		},
		{
			title: '城市',
			dataIndex: 'city',
			key: 'city',
			width: 100,
		},
		{
			title: '注册手机号',
			dataIndex: 'mobile',
			key: 'mobile',
			width: 120,
		},
		{
			title: '姓名',
			key: 'nickname',
			width: 200,
			dataIndex: 'nickname',
		},
		{
			title: '完成订单数',
			dataIndex: 'orderCount',
			key: 'orderCount',
			width: 150,
			render: (createAt) => (<div>{createAt||0}</div>)
		},
		{
			key: 'action',
			action: doAction,
			btn: [{type: 'look', label: '查看'}]
		},
	];
}