import { BrowserRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config';
import { routes } from './route/index'
import {ConfigProvider} from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import React from "react";
import { Provider } from "react-redux";
import store from './store'
import 'moment/locale/zh-cn'
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider locale={zhCN}>
          {renderRoutes(routes)}
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
