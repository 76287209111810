import { Redirect } from 'react-router-dom'
import Index from '../layout/Index'
import Home from '../layout/Home'
import Login from '../pages/login'
import ExamineDriver from '../pages/examine/driver'
import ExamineCar from '../pages/examine/car'
import DriverList from '../pages/driver/list/Index'
import DriverDetail from '../pages/driver/detail/Index'
import OrderList from '../pages/driver/orderList/Index'
import InviteList from '../pages/driver/inviteList/Index'
import OrderDetail from '../pages/driver/orderDetail/Index'
import Feedback from '../pages/driver/feedback/Index'
import ClientList from '../pages/client/list/Index'
import PaymentList from '../pages/payment/list/Index'
import WithdrawList from '../pages/divideAccount/withdraw/Index'
import ClientDetail from '../pages/client/detail/Index'
import {ControlOutlined, HeatMapOutlined, SecurityScanOutlined, TeamOutlined} from "@ant-design/icons";
import React, { lazy } from "react";
import {renderRoutes} from "react-router-config";
function NoPage() {
  return <h2>404</h2>;
}
function routeContainer({route}){
  return renderRoutes(route.routes)
}
function Icon({code}){
  return <i className="iconfont" style={{marginRight: '5px'}}>{code}</i>
}
export const HomeRouter =  [
  {
    label: '首页',
    path: "/home/index",
    exact: true,
    icon: <Icon code="&#xe604;" />,
    component: Home,
  },
  {
    label: '司机端',
    path: "/home/driver",
    component: routeContainer,
    icon: <Icon code="&#xe624;"/>,
    routes: [
      {
        label: '认证管理',
        path: "/home/driver/examine",
        icon: <Icon code="&#xe615;"/>,
        component: routeContainer,
        routes: [
          {
            label: '司机认证',
            path: "/home/driver/examine/driver",
            // icon: <Icon code="&#xe624;"/>,
            exact: true,
            component: ExamineDriver,
          },
          {
            label: '车辆认证',
            path: "/home/driver/examine/car",
            component: ExamineCar,
            // icon: <Icon code="&#xe631;"/>,
            exact: true,
          },
        ]
      },
      {
        label: '司机列表',
        exact: true,
        path: "/home/driver/list",
        icon: <Icon code="&#xe65c;"/>,
        component: DriverList,
      },
      {
        label: '司机详情',
        exact: true,
        path: "/home/driver/detail",
        passive: true,
        component: DriverDetail,
      },
      {
        label: '邀请列表',
        exact: true,
        path: "/home/driver/inviteList",
        icon: <Icon code="&#xe65c;"/>,
        component: InviteList,
      },
      {
        label: '订单列表',
        exact: true,
        path: "/home/driver/orderList",
        icon: <Icon code="&#xe65c;"/>,
        component: OrderList,
      },
      {
        label: '订单明细',
        exact: true,
        path: "/home/driver/orderDetail",
        passive: true,
        component: OrderDetail,
      },
      {
        label: '意见反馈',
        exact: true,
        path: "/home/driver/feedback",
        icon: <Icon code="&#xe600;"/>,
        component: Feedback,
      },
    ]
  },
  {
    label: '用户端',
    path: "/home/client",
    component: routeContainer,
    icon: <Icon code="&#xe602;"/>,
    routes: [
      {
        label: '用户列表',
        exact: true,
        path: "/home/client/list",
        icon: <Icon code="&#xe65c;"/>,
        component: ClientList,
      },
      {
        label: '用户详情',
        exact: true,
        path: "/home/client/detail",
        passive: true,
        component: ClientDetail,
      },
    ]
  },
  {
    label: '支付管理',
    path: "/home/payment",
    component: routeContainer,
    icon: <Icon code="&#xe602;"/>,
    routes: [
      {
        label: '支付列表',
        exact: true,
        path: "/home/payment/list",
        icon: <Icon code="&#xe65c;"/>,
        component: PaymentList,
      },
    ]
  },
  {
    label: '分帐管理',
    path: "/home/divideAccount",
    component: routeContainer,
    icon: <Icon code="&#xe602;"/>,
    routes: [
      {
        label: '提现列表',
        exact: true,
        path: "/home/divideAccount/withdrawList",
        icon: <Icon code="&#xe65c;"/>,
        component: WithdrawList,
      },
    ]
  },
]
/**
 * 父路由不能添加 exact，要不然不能匹配子路由
 * 注意：在动态路由中，绝对路径可能不适用于 route 配置中。
 * */
export const routes = [
  {
    path: "/",
    exact: true,
    label: '首页',
    render: () => <Redirect to={'/home/index'} />
  },
  {
    label: '首页',
    path: "/home",
    component: Index,
    routes: HomeRouter
  },
  {
    path: "/login",
    exact: true,
    label: '登录',
    component: Login
  },
  {
    label: '404',
    component: NoPage
  },
];
