import {formatDate, hidePhone, isU} from "@/src/utils";
import React from "react";
import { statusMapEnum, invalidTag } from "@/src/utils/helper";
import LinkText from "@/src/components/LinkText";

export const tableColumns  = function (doAction){
	return [
		{
			title: '城市',
			dataIndex: 'city',
			width: 100,
		},
		{
			title: '订单状态',
			dataIndex: 'status',
			width: 100,
			render: text => <span>{statusMapEnum[text]||invalidTag}</span>,
		},
		{
			title: '订单号',
			dataIndex: 'sn',
			width: 150,
		},
		{
			title: '下单时间',
			dataIndex: 'createAt',
			width: 160,
			render: (createAt) => (<div>{formatDate(createAt)}</div>)
		},
		{
			title: '司机姓名',
			dataIndex: 'driverName',
			width: 200,
		},
		{
			title: '司机ID',
			width: 100,
			dataIndex: 'driverId',
			render: (text) => (<LinkText link={'/home/driver/detail?driverId='+text}>{text}</LinkText>)
		},
		{
			title: '车辆类型',
			dataIndex: 'carTypeName',
			width: 150,
		},
		{
			title: '应收金额',
			dataIndex: 'beforeChangeAmount',
			width: 100,
			render: (text) => (<div>{isU(text) ? invalidTag: text/100}</div>)
		},
		{
			title: '实收金额',
			dataIndex: 'amount',
			width: 100,
			render: (text) => (<div>{isU(text) ? invalidTag: text/100}</div>)
		},
		{
			title: '支付方式',
			dataIndex: 'paymentName',
			width: 100,
		},
		{
			title: '用户ID',
			dataIndex: 'userId',
			width: 100,
			render: (text) => (<LinkText link={'/home/client/detail?clientId='+text}>{text}</LinkText>)
		},
		{
			title: '司机号码',
			dataIndex: 'driverPhone',
			width: 150,
			render: hidePhone
		},
		{
			title: '用户号码',
			dataIndex: 'clientPhone',
			width: 150,
			render: hidePhone
		},
		{
			title: '接单时间',
			dataIndex: 'verifyTime',
			width: 150,
			render: (createAt) => (<div>{formatDate(createAt)}</div>)
		},
		{
			key: 'action',
			action: doAction,
			btn: [{type: 'look', label: '查看'}]
		},
	];
}