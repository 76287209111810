import React, {useEffect, useRef} from "react";
import { useHistory } from 'react-router-dom';
import {Button, DatePicker, Form, Input, Select, Space} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {getDict} from "@/src/store/config";
import TableView from "@/src/components/view/table/index"
import { tableColumns } from './tableConfig'
import moment from "moment";

const dateFormat = 'YYYY-MM-DD';
// 不可选择的时候
function disabledDate(current) {
	return current && current > moment().endOf('day');
}
export default function List(){
	let history = useHistory();
	const { dict } = useSelector(state => state.config);
	let opinion = dict[2] || []
	const dispatch = useDispatch()

	/*筛选*/
	const [filterForm] = Form.useForm();
	function resetFilter(){
		filterForm.resetFields();
		getTableData(filterForm.getFieldValue())
	}
	/*表格*/
	const viewTable = useRef(null);
	function tableAction(row, type){
		history.push(`/home/driver/orderDetail?sn=${row.sn}`)
	}
	let columns = tableColumns(tableAction)
	useEffect(async () => {
		await dispatch(getDict(2))
		getTableData(filterForm.getFieldValue())
	}, [])
	async function getTableData(params){
		let ps = {
			...params,
			type: 2
		}
		if(ps.commiteDate){
			ps.commiteDate = ps.commiteDate.format(dateFormat)
		}
		viewTable.current.reLoad(ps)
	}
	return (
		<div>
			<div className="filer-layout">
				<Form
					form={filterForm}
					layout="inline"
					onFinish={getTableData}
					initialValues={{dateType: 1, mobileType: 2}}
				>
					<div className="flex">
						<div className="flex-1 relative">
							<Space size={[8, 16]} wrap>
								<Form.Item name="typeCode" label="意见分类">
									<Select style={{width: 140}} placeholder="请选择分类" allowClear>
										{opinion.map(item => (
											<Select.Option key={item.dictCode} value={item.dictCode}>{item.dictName}</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item name="commiteDate" label="提交时间">
									<DatePicker format={dateFormat} disabledDate={disabledDate}/>
								</Form.Item>
							</Space>
						</div>
						<div className="shrink">
							<Form.Item className="shrink">
								<div className="filter-form-control">
									<Button type="primary" htmlType="submit">
										查询
									</Button>
									<Button
										style={{margin: '0 15px'}}
										onClick={resetFilter}
									>
										重置
									</Button>
								</div>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			<div className="table-layout">
				<TableView
					showIndex
					ref={viewTable}
					fetchOption={{url: '/feedback/list', method: 'post'}}
					columns={columns}
				/>
			</div>
		</div>
	)
}