import {formatDate, hidePhone} from "@/src/utils";
import { invalidTag } from "@/src/utils/helper";
import React from "react";
import LinkText from "@/src/components/LinkText";

export const tableColumns  = function (doAction){
	return [
		{
			title: '注册时间',
			dataIndex: 'createAt',
			key: 'createAt',
			width: 150,
			render: (createAt) => (<div>{formatDate(createAt)}</div>)
		},
		{
			title: '城市',
			dataIndex: 'city',
			key: 'city',
			width: 100,
		},
		{
			title: '邀请者名称',
			dataIndex: 'fromUserName',
			key: 'fromUserName',
			width: 120,
			render: hidePhone
		},
		{
			title: '邀请者ID',
			dataIndex: 'fromUserId',
			key: 'fromUserId',
			width: 100,
			render: (text) => (<LinkText link={'/home/driver/detail?driverId='+text}>{text}</LinkText>)
		},
		{
			title: '被邀请者姓名',
			dataIndex: 'toUserName',
			key: 'toUserName',
			width: 120,
		},
		{
			title: '被邀请者ID',
			dataIndex: 'toUserId',
			key: 'toUserId',
			width: 120,
			render: (text) => (<LinkText link={'/home/driver/detail?driverId='+text}>{text}</LinkText>)
		},
		{
			title: '邀请方式',
			key: 'inviteTypeDesc',
			width: 100,
			dataIndex: 'inviteTypeDesc',
		},
	];
}