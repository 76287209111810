import React from 'react'
import logo from '../static/images/logo.png';
import { renderRoutes } from 'react-router-config';
import Menu from './menu'
import Header from '../layout/Header'
import { menuStackContext } from '../context/index'
function Home({route}){
  return (
    <menuStackContext.Provider value={React.useState(menuStackContext.defaultValue)}>
      <div className="app">
        <div className="app-side flex flex-column">
          <div className="app-logo flex align-center">
            <img src={logo} className="logo" alt="logo" />
            <span className="name">码上司机</span>
          </div>
          <div className="app-menu flex-1">
            <Menu></Menu>
          </div>
        </div>
        <div className="app-content flex-1 relative flex flex-column">
          <div className="app-head">
            <Header/>
          </div>
          <div className="app-main flex-1">
            {renderRoutes(route.routes)}
          </div>
        </div>
      </div>
    </menuStackContext.Provider>
  );
}

export default Home;
