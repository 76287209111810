import {formatDate, hidePhone} from "@/src/utils";
import React from "react";
import { Tooltip } from 'antd'

const prepayValidStatusMap = {
	1: '未支付',
	2: '已支付',
}
function CellToolTip(props){
	return (
		<Tooltip title={props.children}><div className="text-ellipsis"> {props.children}</div></Tooltip>
	)
}
export const tableColumns  = function (doAction){
	return [
		{
			title: '发起时间',
			dataIndex: 'createAt',
			nowrap: true,
			width: 140,
			render: (createAt) => (<div className="nowrap">{formatDate(createAt)}</div>)
		},
		{
			title: '订单号SN',
			dataIndex: 'sn',
			nowrap: true,
			width: 160
		},
		{
			title: '付款用户',
			dataIndex: 'userId',
			nowrap: true
		},
		{
			title: '预支持id',
			dataIndex: 'prepayId',
			width: 160
		},
		{
			title: '用户支付状态',
			dataIndex: 'prepayValidStatus',
			nowrap: true,
			render: (val) => (<div className="nowrap">{prepayValidStatusMap[val]}</div>)
		},
		{
			title: '交易时间',
			dataIndex: 'tradeTime',
			nowrap: true,
			width: 140,
			render: (createAt) => (<div className="nowrap">{formatDate(createAt)}</div>)
		},
		{
			title: '微信支付订单号',
			dataIndex: 'transactionId',
			width: 160,
		},
		{
			title: '微信返回支付状态',
			dataIndex: 'tradeState',
		},
		{
			title: '交易类型',
			dataIndex: 'tradeType',
			nowrap: true
		},
		{
			title: '支付返回内容明细',
			dataIndex: 'payContent',
			width: 200,
			render: (text) => <CellToolTip>{text}</CellToolTip>
		},
		{
			title: '商户号',
			dataIndex: 'mchid',
			width: 150
		},
		{
			key: 'action',
			action: doAction,
			btn: [{type: 'look', label: '明细'}]
		},
	];
}
