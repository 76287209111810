import styles from './addrPanel.less'
export default function AddrPanel(props){
	let list = props.list || []
	return (
		<div className={styles["addr-panel"]}>
			{
				list.map((item, index) => {
					let className = `flex ${styles["addr-item"]} align-center`
					return (
						<div className={className} key={index}>
							<div className={`flex ${styles["origin-icon-box"]} shrink`}>
								{index === 0
									? <div className={styles["origin-icon"]}>发</div>
									: index === list.length - 1
										?	<div className={styles["destination-icon"]}>收</div>
										: <div className={styles["origin-dot"]}></div>
								}
							</div>
							<div className={styles["addr-panel-content"]}>
								<div className={styles["addr-name"]}>
									{item.abbr||''}
								</div>
								<div className={styles["add-des"]}>
									{item.particular || ''}
								</div>
							</div>
						</div>
					)
				})
			}
		</div>
	)
}