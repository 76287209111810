import React from "react";
import { useHistory } from "react-router-dom";

function LinkText(props){
	let history = useHistory();
	function navigateOrderDetail(){
		history.push(props.link)
	}
	return (
		<span
			className="is-link"
			style={{color: '#FF5103', cursor: 'pointer'}}
			onClick={navigateOrderDetail}
		>
			{props.children}
		</span>
	)
}
export default LinkText