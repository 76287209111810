import {formatDate, hidePhone} from "@/src/utils";
import { invalidTag } from "@/src/utils/helper";
import React from "react";

export const tableColumns  = function (doAction){
	return [
		{
			title: '司机ID',
			dataIndex: 'userId',
			key: 'userId',
			width: 100,
		},
		{
			title: '注册时间',
			dataIndex: 'createAt',
			key: 'createAt',
			width: 150,
			render: (createAt) => (<div>{formatDate(createAt)}</div>)
		},
		{
			title: '城市',
			dataIndex: 'city',
			key: 'city',
			width: 100,
		},
		{
			title: '注册手机号',
			dataIndex: 'mobile',
			key: 'mobile',
			width: 120,
			render: hidePhone
		},
		{
			title: '司机姓名',
			key: 'nickname',
			width: 250,
			dataIndex: 'nickname',
		},
		{
			title: '车辆类型',
			dataIndex: 'carTypeName',
			width: 150,
		},
		{
			title: '车牌号',
			dataIndex: 'carNumber',
			key: 'carNumber',
			width: 150,
		},
		{
			title: '完成订单数',
			dataIndex: 'orderCount',
			key: 'orderCount',
			width: 150,
		},
		{
			title: '评分',
			dataIndex: 'score',
			key: 'score',
			width: 100,
			render: (t) => (<div>{isNaN(t) ? invalidTag : t/10}</div>)
		},
		{
			key: 'action',
			action: doAction,
			btn: [{type: 'look', label: '查看'}]
		},
	];
}