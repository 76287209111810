import React from "react";
import { Row, Col, Image } from 'antd'
import TextList from '@/src/components/view/textList'
import { dateYYD } from '@/src/utils/index'

export default function Page({dataInfo = {}}){
	if(!isNaN(dataInfo)){
		dataInfo = {}
	}
	let dataList = [
		{ label: '车辆ID', value: 'id'},
		{ label: '车牌号', value: 'carNumber'},
		{ label: '车牌颜色', value: 'carColor'},
		{ label: '车辆识别代号', value: 'vin'},
		{ label: '行驶证所有人', value: 'drivingLicenseOwner'},
		{ label: '行驶证类型', value: 'drivingLicenseOwnerType'},
		{ label: '车辆类型', value: 'typeName'},
		{ label: '特殊规格', value: 'specialSpecifi'},
		{ label: '车辆尺寸', value: 'lwh', render: val => <span>{val}米</span>},
		{ label: '车辆载重', value: 'loadKg', render: val => <span>{val}公斤</span>},
		{ label: '车辆体积', value: 'loadVol', render: val => <span>{val}方</span>},
		{ label: '申请认证时间', value: 'applyTime', render: dateYYD},
		{ label: '认证完成时间', value: 'auditTime', render: dateYYD},
	]
	return (
		<div className="panel">
			<Row>
				<Col span={12}>
					<div className="text-title">基本资料：</div>
					{TextList({ dataList, dataSource: dataInfo })}
				</Col>
				<Col span={12}>
					<div className="text-title">附加资料：</div>
					<Row>
						<Col span={12}>
							<div className="col-value">行驶证正面</div>
							<Image
								width={200}
								src={dataInfo.drivingLicenseFront}
							/>
						</Col>
						<Col span={12}>
							<div className="col-value">行驶证反面</div>
							<Image
								width={200}
								src={dataInfo.drivingLicenseVerso}
							/>
						</Col>
					</Row>
					<Row style={{marginTop: '50px'}}>
						<Col span={12}>
							<div className="col-value">车辆45°角侧面照</div>
							<Image
								width={200}
								src={dataInfo.carPicSide}
							/>
						</Col>
						<Col span={12}>
							<div className="col-value">车辆正面照</div>
							<Image
								width={200}
								src={dataInfo.carPicFront}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	)
}