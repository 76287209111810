import { useHistory } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import './login.less'
import {fetch, userToken} from "@/src/utils/request";
import { message } from 'antd';
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

export default function Login(){
  let history = useHistory();
  const onFinish = async values => {
    const response = await fetch({
      url: '/login/backStageLogin',
      method: 'post',
      data: values
    })
    if(!response.err){
      userToken.setValue(response.data.data.token)
      message.success('登录成功')
      history.push('/')
    }
    console.log('response', response)
  };
  return (
    <div className="login-wrap">
      <div className="login-form">
        <div className="title">码上司机登录系统</div>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label="账号"
            name="username"
            rules={[{ required: true, message: '请输入用户名!' }]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: '请输入密码!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" block size="large">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
