import React, {useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {Col, Row} from "antd";
import { invalidTag } from '@/src/utils/helper'


// let labelLayout = { span: 7 }
let labelLayout = {}
let valueLayout = { flex: 1 }
const alignCenterStyle = {
	'display': 'flex',
	'alignItems': 'center',
	'height': '100%',
};

function ViewText(props){
	const {
		dataList = [], // 列表
		labelStyle = {}, // label 样式
		colon = '：' // label后的符号
	} = props
	let dataSource = props.dataSource || {}
	if(props.labelLayout){
		labelLayout = props.labelLayout
	}
	if(props.valueLayout){
		labelLayout = props.valueLayout
	}
	return (
		<>
			{
				dataList.map((item, index) => (
					<Row wrap={false} key={index} className="text-list">
						<Col {...labelLayout}>
							<div style={item.alignCenter ? alignCenterStyle: null}>
								<div className="col-label" style={labelStyle}>{item.label||invalidTag}{colon}</div>
							</div>
						</Col>
						<Col {...valueLayout}>
							<div style={item.alignCenter ? alignCenterStyle: null}>
								{
									item.text ? (<div className="col-value">{item.text||invalidTag}</div>) : (
										<div className="col-value">
											{item.render ? item.render(dataSource[item.value], dataSource): dataSource[item.value]||invalidTag}
										</div>
									)
								}
							</div>

						</Col>
					</Row>
				))
			}
		</>
	)
}

export default ViewText
