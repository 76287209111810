import { fetch } from "@/src/utils/request";

export async function getOrderInfo(sn){
	return fetch({
		url: '/order/getOrderDetail',
		params: {
			sn
		}
	})
}


