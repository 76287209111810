import { fetch } from "@/src/utils/request";

export async function getDriverInfo(userId){
	return fetch({
		url: '/driver/getDriverInfo',
		params: {
			userId
		}
	})
}

export async function getCarInfo(userId){
	return fetch({
		url: '/driver/getCarInfo',
		params: {
			userId
		}
	})
}

